<template>
  <Card class="intro-card" hasShadow>
    <section class="intro-card__wrapper">
      <div class="intro-card__wrapper-intro__text">
        <p>
          Are you an Expert Storyteller during a Walkaround? <br />
          <b>It’s Time to Walk the Talk!</b>
        </p>
        <p
          :style="{
            fontFamily: siteVariable === 'Acura ZDX' ? 'Avenir Black' : 'Myriad Pro',
          }"
        >
          Bring your Walkaround to life through Storytelling and you could score cash, prizes and a
          trip to Vegas!
        </p>
        <p>
          {{ variables[siteVariant].storytellerIntroText }}
        </p>
      </div>
      <div class="intro-card__wrapper-img__wrapper">
        <img class="intro-card__wrapper-img" :src="cardImage" alt="Las Vegas view" />
      </div>
    </section>
  </Card>
  <p class="video-intro">
    View effective storytelling examples from last year's Acura finals:
  </p>
  <div class="video__wrapper">
    <iframe
      class="video__frame"
      src="https://player.vimeo.com/video/986689986"
      frameborder="0"
      allowfullscreen
      allow="autoplay; fullscreen; picture-in-picture"
      title="home video"
    ></iframe>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { useRoute } from 'vue-router';

import useVariant from '@/utils/useVariant';

import Card from '@/components/TheCard.vue';
import DesktopImage from '@/assets/img/bg_card.png';
import MobileImage from '@/assets/img/bg_card-mobile.png';

const route = useRoute();

const variables = {
  acura: {
    storytellerIntroText: `
      Top performers excel by demonstrating how the ZDX enhances their clients’ lives.
      Tune up your walkaround through storytelling. Grab a camera and a colleague and
      show us how you craft stories.
    `,
  },
  honda: {
    storytellerIntroText: `
    Top performers excel by showing customers how the PROLOGUE enhances their lives.
    Tune up your video storytelling skills. Grab a camera, a colleague and show us how
    you craft stories with your walkaround video.
    `,
  },
};

const siteVariant = useVariant(route.params);

const cardImage = computed(() => {
  const isMobile = window.innerWidth < 768;
  const image = isMobile ? MobileImage : DesktopImage;
  return image;
});
</script>

<style lang="scss" scoped>

.intro-card {
  margin-top: -250px;
  max-width: 84vw;
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-family: var(--font-primary);
    &-intro__text {
      line-height: normal;
      padding: 78px 90px 78px 71px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 26px;
      :nth-child(1) {
        font-size: clamp(1.8125rem, 1.4718rem + 1.2735vw, 3rem);
        b {
          font-size: clamp(1.8125rem, 1.4718rem + 1.2735vw, 3rem);
          color: black;
        }
      }
      :nth-child(2) {
        color: #4291d1;
        font-size: clamp(1.5rem, 1.3028rem + 0.7373vw, 2.1875rem);
      }
      :nth-child(3) {
        font-size: clamp(1.125rem, 0.9457rem + 0.6702vw, 1.75rem);
      }
    }
    &-img {
      &__wrapper {
        position: relative;
        margin: 12px;
        &::before {
          content: '';
          width: 85px;
          height: 89px;
          display: block;
          position: absolute;
          top: -2px;
          right: -1px;
          border-bottom-left-radius: 16px;
          background: white;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 7px;
          width: 46px;
          height: 70px;
          right: 15px;
          background: url('@/assets/img/spotmark.svg') no-repeat center;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.video-intro {
  margin-top: 65px;
  font-size: clamp(1.8125rem, 1.3284rem + 1.8097vw, 3.5rem);
  font-weight: 300;
  line-height: normal;
  font-family: var(--font-primary);
  max-width: 90vw;
  margin: 0 auto;
}

.video__wrapper {
  position: relative;
  padding-bottom: 50%;
  height: 0;
  width: 100%;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  background: #000;
  border-radius: 30px;
}

.video__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 30px;
}

@media screen and (max-width: 767px) {
  .intro-card {
    margin-top: -30vw;
    max-width: 94vw;
    &__wrapper {
      display: flex;
      flex-direction: column-reverse;
      &-intro__text {
        padding: 30px 20px;
      }
    }
  }

  .video-intro {
    margin-top: 0;
  }
}
</style>
