<template>
  <header class="acura-header" :class="winnersPage ? 'winners' : ''" :style="dynamicStyles">
    <a class="acura-header__logo" href="#" title="Acura Storytelling Competition">
      <img class="acura-header__logo" :src="siteLogo" alt="Logo Acura" />
    </a>
    <div class="date-extension-banner" v-if="!winnersPage">
      <p>THE COMPETITION DEADLINE</p>
      <p>HAS BEEN EXTENDED TO 8/30!</p>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.acura-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0;
  background: black;
  &.winners {
    padding-bottom: 40px;
    border-bottom: 1px solid #F3F3F3;
    @media screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
  }

  a {
    display: block;
  }

  img {
    max-width: 160px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 40px 0 0;

    img {
      max-width: none;
    }
  }
}

.date-extension-banner {
  margin-top: 32px;
  padding-top: 2px;
    width: 100%;
  p {
    background: #e82c2a;
    color: white;
    font-family: 'Avenir';
    padding: 8px 5vw;
    font-size: clamp(1.25rem, 0.55rem + 3.5vw, 4.75rem);
    line-height: normal;
    font-weight: 500;
  }
  border-top: 1px solid #F3F3F3;
  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
  @media screen and (min-width: 1044px) {
    p {
      line-height: 100%;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useDynamicStyles from '@/utils/BrandFonts';

import AcuraLogo from '@/assets/img/acura-logo.svg';
import HondaLogo from '@/assets/img/honda-logo.svg';
import useVariant from '@/utils/useVariant';

defineProps({
  winnersPage: Boolean,
});

const route = useRoute();

const variant = useVariant(route.params);
const dynamicStyles = useDynamicStyles(variant);

const siteLogo = computed(() => {
  const { brand } = route.params;
  return brand?.toLowerCase() === 'zdx' ? AcuraLogo : HondaLogo;
});
</script>
