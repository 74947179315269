<template>
  <div
    class="custom-checkbox"
    :class="{ 'custom-checkbox--invalid is-invalid': dirty && !!errorMessage }"
    :data-checked="isChecked"
    :data-invalid="dirty && !!errorMessage"
    :data-disabled="disabled"
  >
    <label class="custom-checkbox__label" :for="id">
      <input
        v-model="inputValue"
        class="custom-checkbox__input"
        type="checkbox"
        :id="id"
        :value="value"
        :disabled="disabled"
      />
      <div class="custom-checkbox__control">
        <svg
          class="custom-checkbox__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
      <div class="custom-checkbox__text-wrapper">
        <slot>
          <span v-html="text" class="custom-checkbox__text" />
        </slot>
      </div>
    </label>
    <small class="custom-checkbox__error-message">
      Field is required
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [Boolean, Array],
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  dirty: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
});

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});

const isChecked = computed(() => inputValue.value);
</script>

<style scoped lang="scss">
.custom-checkbox {
  width: fit-content;

  &--invalid {
    & .custom-checkbox__control {
      border-color: red;
    }
    & .custom-checkbox__error-message {
      display: block;
      margin-left: 48px;
      margin-bottom: 16px;
    }
  }

  &__label {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  &__control {
    display: grid;
    place-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #FFF;
    border: 2px solid #C9C9C9;
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;

    &[data-disabled='true'] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__icon {
    display: none;
    width: 2rem;
    height: 2rem;
    stroke: #4291d1;
    top: -0.125rem;
    left: -0.1rem;
    position: absolute;
  }

  &__text-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    transition: opacity 0.3s;

    &[data-disabled='true'] {
      opacity: 0.5;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    line-height: 1;
    user-select: none;
  }

  &__error-message {
    display: none;
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }

  input:focus-visible + .custom-checkbox__control {
    outline: 1px solid;
    outline-offset: 4px;
  }

  &[data-checked='true'] .custom-checkbox__icon {
    display: block;
  }
}
</style>
