<template>
  <div class="home__wrapper" :style="dynamicStyles">
    <IntroTitle showWinners national />
    <Card class="card__first" hasShadow>
      <template v-if="loadingNational">
        <TheLoading />
      </template>
      <template v-else>

      <div class="card__national__wrapper">
        <Card
          class="card__national-finalist"
          :key="'national-' + nationalFinalist.lastName"
          v-for="nationalFinalist in nationalFinalists"
        >
          <p class="card__national-finalist__position">
            {{ parsePosition(nationalFinalist.place) }} WINNER
          </p>
          <hr class="card__divider" />
          <p class="card__national-finalist__position__name">
            {{ nationalFinalist.firstName }}
            {{ nationalFinalist.lastName }}
          </p>
          <p class="card__national-finalist__dealership-name">
            {{ nationalFinalist.dealershipName }}
          </p>
        </Card>
      </div>
      <Card class="card__remaining">
        <p class="card__remaining__title">Remaining finalists</p>
        <div
          class="card__remaining__position"
          :key="'zone-winner-' + winner.firstName"
          v-for="winner in zoneWinners"
        >
          <p class="card__remaining__position__name">
            {{ winner.firstName }} {{ winner.lastName }}
          </p>
          <hr class="card__remaining__divider" />
          <p class="card__remaining__position__dealer-name">{{ winner.dealershipName }}</p>
        </div>
      </Card>
    </template>
    </Card>
  </div>
  <div class="footer__wrapper">
    <p class="footer__text">
      * Must travel to and compete in National Competition to receive National Prizes. Participants
      for the National Competition were chosen from ALL valid video submissions regardless of Zone.
    </p>
    <img class="footer__image" src="@/assets/img/winners-bg.png" alt="Las vegas view" />
    <span class="footer__overlay"></span>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDynamicStyles from '@/utils/BrandFonts';
import useVariant from '@/utils/useVariant';
import IntroTitle from '@/components/intro/IntroTitle.vue';
import Card from '@/components/TheCard.vue';

import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import api from '@/api';
import TheLoading from '@/components/TheLoading.vue';
import { useMeta } from 'vue-meta';

const route = useRoute();
const variant = useVariant(route.params);
const dynamicStyles = useDynamicStyles(variant);
const toast = useToast();

const loadingNational = ref(false);
const nationalFinalists = ref([]);

const zoneWinners = ref([]);

useMeta({
  description: 'My awesome site',
});

const fetchNationalFinalists = async () => {
  loadingNational.value = true;
  try {
    const { data } = await api.data.getNationalWinners(variant);
    nationalFinalists.value = data.slice(0, 4);
    zoneWinners.value = data.slice(4);
  } catch {
    toast.error('An error ocurred while fetching national finalists. Please try again.');
  } finally {
    loadingNational.value = false;
  }
};

const parsePosition = (position) => {
  const titles = {
    1: 'GRAND PRIZE',
    2: '2ND PLACE',
    3: '3RD PLACE',
    4: '4TH PLACE',
  };
  return titles[position];
};

onMounted(() => {
  // fetchRemainingFinalists();
  fetchNationalFinalists();
});
</script>

<style scoped lang="scss">
@mixin glow {
  border: 3px solid #5a91d1;
  box-shadow: 0px 0px 6px #5a91d1;
}

.card {
  // padding: 56px 8px;
  max-width: 94vw;
  font-family: var(--font-tertiary);
  text-transform: uppercase;
  &:not(.card__remaining) {
    @media screen and (min-width: 700) {
      aspect-ratio: 4/3.1;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__first {
    margin-top: 0;
    border-radius: 0px;
    max-width: 100vw;
    padding: 32px 0px;
    @media screen and (min-width: 768px) {
      margin-top: -100px;
      border-radius: 30px;
      max-width: 92vw;
      padding: 56px 50px;
    }
  }

  &__divider {
    background: #5a91d1;
    border: none;
    border-radius: 20px;
    height: 3px;
  }
  &__national-finalist,
  &__remaining {
    @include glow;
  }
  &__national-finalist {
    line-height: normal;
    padding: 32px 32px;
    @media screen and (min-width: 768px) {
      padding: 0px 32px;
    }
    &__dealership-name {
      font-size: clamp(1.5rem, 1.43rem + 0.2667vw, 1.75rem);
      color: #767676;
    }
    &:first-child {
      .card__national-finalist__position {
        font-size: clamp(3.1875rem, 2.6975rem + 1.8667vw, 4.9375rem);
        &__name {
          font-size: clamp(2.25rem, 2.0575rem + 0.7333vw, 2.9375rem);
        }
      }
    }
    .card__divider {
      width: 70%;
    }
    &:not(:first-child) {
      .card__national-finalist__position {
        font-size: clamp(2.6875rem, 2.565rem + 0.4667vw, 3.125rem);
        &__name {
          font-size: clamp(2.1875rem, 2.1rem + 0.3333vw, 2.5rem);
        }
      }
    }
  }

  &__national__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    :first-child {
      grid-column: span 3 / span 3;
    }
    @media screen and (max-width: 1126px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__remaining {
    margin-top: 64px;
    padding: 50px 16px 16px;

    &__title {
      font-family: var(--font-tertiary);
      // font-weight: 400;
      letter-spacing: 1.8px;
      font-size: clamp(2.6875rem, 2.562rem + 0.4692vw, 3.125rem);
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 32px;
    }

    &__position {
      display: grid;
      grid-template-columns: 60% 3px calc(40% - 3px);
      padding: 6px 0;
      line-height: 350%;
      text-align: left;
      text-transform: uppercase;
      font-family: 'Avenir Book';
      &:not(:last-child) {
        border-bottom: 1px solid #707070;
      }

      &__dealer-name {
        font-size: clamp(1.25rem, 1.18rem + 0.2667vw, 1.5rem);
        padding-left: 8px;
      }

      &__name {
        font-size: clamp(1.5rem, 1.36rem + 0.5333vw, 2rem);
      }

      @media screen and (max-width: 1126px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__dealer-name {
          padding: 0;
        }
        &__name {
          text-align: center;
        }
      }
    }

    &__divider {
      background: #5a91d1;
      border: none;
      width: 3px;
      height: 100%;
      margin: 0;

      @media screen and (max-width: 1126px) {
        width: 40px;
        height: 3px;
        margin: 8px 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 92vw;
    padding: 56px 50px;
    aspect-ratio: unset !important;
  }
}

.footer {
  &__wrapper {
    position: relative;
    background: black;
    // background-image: linear-gradient(
    //   to bottom,
    //   #FFF 0%,
    //   #FFF 25%,
    //   rgba(0, 0, 0) 40%
    // );
    @media screen and (min-width: 768px) {
      margin-top: 24px;
    }
  }
  &__image {
    width: 100%;
    max-width: auto;
  }
  &__overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: linear-gradient(
      to bottom,
      #FFF 0%,
      #FFF 25%,
      rgba(255, 255, 255, 0) 40%
    );

    @media screen and (max-width: 419px) {
      background-image: linear-gradient(
        to bottom,
        #FFF 0%,
        #FFF 50%,
        rgba(255, 255, 255, 0) 70%
      );
    }

    @media screen and (min-width: 420px) and (max-width: 767px) {
      background-image: linear-gradient(
        to bottom,
        #fff 0%,
        #fff 45%,
        rgba(255, 255, 255, 0) 70%
      );
    }
    @media screen and (min-width: 768px) {
      background-image: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0) 30%);
    }
  }
  &__text {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 20px 15% 0;
    background: transparent;
    font-size: clamp(0.9375rem, 0.8077rem + 0.5769vw, 1.5rem);
    line-height: normal;
    font-style: italic;
    font-weight: 500;
    font-family: var(--font-primary);
    @media screen and (max-width: 767px) {
      position: relative;
      padding: 10px 8% 0;
    }
  }
}
</style>
