<template>
  <section
    class="acura-intro__title"
    :class="showWinners ? 'winners' : ''"
    :style="national ? { minHeight: '450px' } : {}"
  >
    <img src="@/assets/img/EVolution_logo.png" alt="Evolution Logo" />
    <h1
      class="acura-intro__event-date"
      :style="{
        fontSize:
          siteVariant === 'acura'
            ? 'clamp(1.8125rem, -0.1059rem + 7.1716vw, 8.5rem)'
            : 'clamp(2rem, 0.8167rem + 4.4236vw, 6.125rem)',
        fontWeight: 700,
      }"
      >2024 {{ variables[siteVariant].pageTitle }}</h1
    >
    <span class="acura-intro__event-description"> Storytelling Competition </span>
    <template v-if="showWinners && !national">
      <h2 class="winners__title">Congratulations Winners!</h2>
      <p class="winners__subtitle">
        National Finalists will compete for national honors,
        {{ variables[siteVariant].salesCredits }} Sales Credits, a Motocompacto and cash prizes in
        Las Vegas {{ variables[siteVariant].competitionDate }}.*
      </p>
    </template>
    <template v-if="showWinners && national">
      <h2 class="national-winners__title">Congratulations National Finalists!</h2>
      <p class="national-winners__subtitle">
        The National Finalists for the {{ variables[siteVariant].competitionName }} competed for top
        honors in Las Vegas on October {{ siteVariant === 'acura' ? '15th' : '17th' }}!
      </p>
      <p class="national-winners__subtitle">
        Our Finalists shared thousands of dollars in cash prizes and
        {{ variables[siteVariant].salesCredits }} Sales Credits – please help us congratulate:
      </p>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.acura-intro {
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('@/assets/img/bg_hero.png') no-repeat center, #000;
    background-size: cover;
    color: white;

    &.winners {
      background-size: 100%;
      background-position-y: bottom;
      min-height: 550px;
    }
    img {
      margin-top: 42px;
      max-width: 65vw;
    }

    :last-child {
      margin-bottom: 50px;
    }
  }
  &__event-date,
  &__event-description {
    display: block;
    line-height: normal;
    text-transform: uppercase;
    font-family: var(--font-primary);
  }
  &__event-date {
    margin-top: 87px;
    font-size: clamp(1.8125rem, -0.1059rem + 7.1716vw, 8.5rem);
    line-height: 100%;
  }
  &__event-description {
    font-size: clamp(1.375rem, 0.0913rem + 5.7051vw, 6.9375rem);
  }
}

.winners {
  font-family: var(--font-primary);
  &__title {
    line-height: normal;
    font-size: clamp(2rem, 1.1rem + 4.5vw, 6.5rem);
    text-transform: uppercase;
    font-weight: 700;
  }
  &__subtitle {
    line-height: 110%;
    font-size: clamp(1.5rem, 1.2125rem + 1.4375vw, 2.9375rem);
    max-width: 90vw;
    font-weight: 300;
    padding-bottom: 48px;
  }
}

.national-winners {
  font-family: var(--font-primary);
  &__title {
    line-height: 100%;
    font-size: 4.43vw;
    text-transform: uppercase;
    font-weight: 700;
    padding: 64px 0;
  }
  &__subtitle {
    line-height: 110%;
    font-size: 3.5vw;
    max-width: 86vw;
    font-weight: 300;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .acura-intro {
    &__title {
      // height: 75vw;
      padding-top: 0;
      margin-top: 0;
      img {
        margin: 16px 0;
        max-width: 65vw;
      }

      &:not(.winners) {
        :last-child {
          margin-bottom: 25vw;
        }
      }
    }
    &__event-date {
      margin-top: 8px;
    }
    &__event-description {
      // margin: 8px 0;
    }
  }

  .national-winners {
    &__title {
      font-size: 7.9vw;
      padding: 26px 0;
    }
    &__subtitle {
      font-size: 5.54vw;
      max-width: 75.3vw;
    }
  }
}

@media screen and (min-width: 768px) {
  .acura-intro {
    &__title {
      :last-child {
        margin-bottom: 150px;
      }

      &:not(.winners) {
        :last-child {
          margin-bottom: 300px;
        }
      }
    }
  }
}
</style>

<script setup>
import { useRoute } from 'vue-router';

import useVariant from '@/utils/useVariant';

defineProps({
  showWinners: Boolean,
  national: Boolean,
});

const route = useRoute();

const variables = {
  acura: {
    pageTitle: 'Acura ZDX',
    salesCredits: 'COSE',
    competitionDate: 'October 14-16, 2024',
    competitionName: '2024 Acura ZDX Storytelling Competition',
  },
  honda: {
    pageTitle: 'Honda Prologue Walkaround',
    salesCredits: 'COSL',
    competitionDate: 'October 16-18, 2024',
    competitionName: '2024 Honda Prologue Walkaround Storytelling Competition',
  },
};

const siteVariant = useVariant(route.params);
</script>
