<template>
  <form class="details__wrapper" @submit.prevent="submitForm">
    <TheFieldset legend="Submission Data" class="details__submission">
      <InputGroup>
        <label>Submit Date:</label>
        <p>{{ toLocaleDateString(state.createdOn) }}</p>
      </InputGroup>
      <InputGroup>
        <label>First Name*:</label>
        <InputText
          :maxLength="50"
          fluid
          v-model="state.firstName"
          :invalid="checkFieldInvalid(v$.firstName)" />
      </InputGroup>
      <InputGroup>
        <label>Last Name*:</label>
        <InputText
          :maxLength="100"
          fluid
          v-model="state.lastName"
          :invalid="checkFieldInvalid(v$.lastName)" />
      </InputGroup>
      <InputGroup>
        <label>Mobile*:</label>
        <NewInput
          fluid
          v-model="state.phone"
          mask="(000) 000-0000"
          :maxLength="14"
          :invalid="checkFieldInvalid(v$.phone)" />
      </InputGroup>
      <InputGroup>
        <label>Email*:</label>
        <InputText
          fluid
          v-model="state.email"
          :maxLength="150"
          :invalid="checkFieldInvalid(v$.email)" />
      </InputGroup>
      <InputGroup>
        <label>DPTS ID*:</label>
        <InputText
          fluid
          v-model="state.dptsid"
          :maxLenght="10"
          :invalid="checkFieldInvalid(v$.dptsid)" />
      </InputGroup>
      <InputGroup>
        <label>Zone*:</label>
        <Select
          size="md"
          v-model="state.zoneNumber"
          :options="zoneOptions"
          :invalid="checkFieldInvalid(v$.zoneNumber)" />
      </InputGroup>
      <InputGroup>
        <label>District*:</label>
        <Select
          size="md"
          v-model="state.districtLetter"
          :options="districtOptions"
          :invalid="checkFieldInvalid(v$.districtLetter)" />
      </InputGroup>
      <InputGroup>
        <label>Dealership Name*:</label>
        <InputText
          fluid
          v-model="state.dealershipName"
          :invalid="checkFieldInvalid(v$.dealershipName)" />
      </InputGroup>
      <InputGroup>
        <label>Dealership Number*:</label>
        <InputText
          fluid
          :maxLength="7"
          v-model="state.dealerNumber"
          :invalid="checkFieldInvalid(v$.dealerNumber)" />
      </InputGroup>
      <InputGroup>
        <label>Video Link*:</label>
        <InputText
          fluid
          v-model="state.youtubeLink"
          :maxLength="200"
          :invalid="checkFieldInvalid(v$.youtubeLink)" />
      </InputGroup>
    </TheFieldset>
    <TheFieldset legend="Status and Scoring" class="details__status">
      <div class="scoring__wrapper">
        <p>Status</p>
        <InputGroup class="checkbox-group">
          <Checkbox v-model="state.reviewed" :binary="true" />
          <label>
            Reviewed {{ state.reviewedDate ? `- ${toLocaleDateString(state.reviewedDate)}` : '' }}
          </label>
        </InputGroup>
        <InputGroup class="checkbox-group">
          <Checkbox v-model="state.rejected" :binary="true" />
          <label>
            Rejected
            {{ state.rejectedDate
              ? `- ${toLocaleDateString(state.rejectedDate)}`
              : '' }}
          </label>
        </InputGroup>
        <InputGroup class="checkbox-group">
          <Checkbox v-model="state.confirmationSent" :binary="true" />
          <label>Confirmation Email Sent</label>
        </InputGroup>
        <InputGroup class="checkbox-group">
          <Checkbox v-model="state.rejectionSent" :binary="true" />
          <label>Rejection Email Sent</label>
        </InputGroup>
      </div>
      <div class="scoring__wrapper">
        <p>Scoring</p>
        <InputGroup>
          <label>EV App:</label>
          <Select size="md" :options="EvApp" v-model="state.evAppScore" />
        </InputGroup>
        <InputGroup>
          <label>Storytelling:</label>
          <Select size="md" :options="Storytelling" v-model="state.storytellingScore" />
        </InputGroup>
        <InputGroup>
          <label>Accuracy:</label>
          <Select size="md" :options="Accuracy" v-model="state.accuracyScore" />
        </InputGroup>
        <InputGroup>
          <label>Enthusiasm:</label>
          <Select size="md" :options="Enthusiasm" v-model="state.enthusiasmScore" />
        </InputGroup>

        <InputGroup>
        <label>Total:</label>
        <p>{{ totalScoreSum }}</p>
      </InputGroup>
      </div>
    </TheFieldset>
    <div class="details__notes">
      <InputGroup class="notes-group">
        <label>Judge Comments</label>
        <Textarea v-model="state.judgeComments" cols="30" />
      </InputGroup>
      <InputGroup class="notes-group">
        <label>PHQ Comments</label>
        <Textarea v-model="state.phqComments" cols="30" />
      </InputGroup>
    </div>
    <div class="details__footer">
      <Button
        class="submit-btn"
        type="submit"
        :disabled="isSubmitting"
        :aria-label="!isSubmitting ? 'Save' : 'Saving'"
        :label="!isSubmitting ? 'Save' : 'Saving...'"
      />
    </div>
  </form>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import TheFieldset from '@/components/TheFieldset.vue';
import Checkbox from 'primevue/checkbox';
import InputGroup from 'primevue/inputgroup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Select from '@/components/NewSelect.vue';
import { unmaskPhoneNumber, toLocaleDateString } from '@/utils/format';

import {
  requiredValidator,
  phoneValidator,
  emailValidator,
  youtubeValidator,
} from '@/utils/form';

import useValidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import {
  Accuracy,
  Enthusiasm,
  EvApp,
  Storytelling,
} from '@/data/ScoreOptions';
import api from '@/api';
import NewInput from '../NewInput.vue';

const toast = useToast();

const props = defineProps({
  apiKey: String,
  selectedEntry: Object,
});
const emit = defineEmits(['handleClose']);

const state = ref(
  props.selectedEntry || {
    id: '',
    createdOn: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    dptsid: '',
    zoneNumber: '',
    districtLetter: '',
    dealershipName: '',
    dealerNumber: '',
    youtubeLink: '',
    rejected: false,
    rejectedDate: null,
    reviewed: false,
    reviewedDate: null,
    confirmationSent: false,
    rejectionSent: false,
    registrationType: 1,
    evAppScore: null,
    storytellingScore: null,
    accuracyScore: null,
    enthusiasmScore: null,
    judgeComments: null,
    phqComments: null,
    totalScore: 0,
  },
);

const totalScoreSum = computed(() => {
  let sum = 0;

  if (state.value.evAppScore) {
    sum += +state.value.evAppScore;
  }
  if (state.value.storytellingScore) {
    sum += +state.value.storytellingScore;
  }
  if (state.value.accuracyScore) {
    sum += +state.value.accuracyScore;
  }
  if (state.value.enthusiasmScore) {
    sum += +state.value.enthusiasmScore;
  }

  return sum;
});
const districtOptions = ref([]);
const zoneOptions = ref([]);
const isSubmitting = ref(false);

const rules = computed(() => ({
  firstName: { requiredValidator },
  lastName: { requiredValidator },
  phone: { requiredValidator, phoneValidator },
  email: { requiredValidator, emailValidator },
  dptsid: {
    requiredValidator,
  },
  zoneNumber: { requiredValidator },
  districtLetter: { requiredValidator },
  dealershipName: { requiredValidator },
  dealerNumber: { requiredValidator },
  youtubeLink: { requiredValidator, youtubeValidator },
}));

const v$ = useValidate(rules, state.value, { $autoDirty: true });

const checkFieldInvalid = (field) => {
  const invalid = field.$dirty && field.$errors[0]?.$message;
  return invalid;
};

const postSubmission = async (formValues) => {
  const {
    id,
    rejectedDate,
    reviewedDate,
    ...body
  } = formValues;
  body.evAppScore = +body.evAppScore;
  body.storytellingScore = +body.storytellingScore;
  body.accuracyScore = +body.accuracyScore;
  body.enthusiasmScore = +body.enthusiasmScore;
  body.totalScore = totalScoreSum.value;
  body.phone = unmaskPhoneNumber(body.phone);
  try {
    await api.registrationForm.putRegistration(id, body, props.apiKey);
    toast.success('Success! Your changes have been saved.', {
      timeout: 10000,
    });
    emit('handleClose');
  } catch ({ response }) {
    toast.error(response.data.message || 'An error ocurred while submitting, please try again.', {
      timeout: 20000,
    });
  } finally {
    isSubmitting.value = false;
  }
};

const submitForm = async () => {
  isSubmitting.value = true;
  await v$.value.$validate();
  if (!v$.value.$error) {
    postSubmission(state.value);
  } else {
    const toastMessage = v$.value.$errors[0].$validator === 'requiredValidator' ? 'Please provide values for all required fields.' : v$.value.$errors[0].$message;
    toast.error(toastMessage);
    isSubmitting.value = false;
  }
};

const fetchZoneCodes = async () => {
  try {
    const { data } = await api.data.getZoneCodes();
    zoneOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Zone Codes. Please try again.');
  }
};
const fetchDistricts = async () => {
  try {
    const { data } = await api.data.getDistrictLetters();
    districtOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Districts. Please try again.');
  }
};

onMounted(() => {
  fetchZoneCodes();
  fetchDistricts();
});
</script>

<style scoped lang="scss">
.p-dialog-header {
  justify-content: end;
}

.p-inputtext {
  border-radius: var(--p-inputgroup-addon-border-radius) !important;
}

.details {
  &__wrapper {
    display: grid;
    grid-template-columns: 34.5% 30% 34.5%;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 34.5% 30% 34.5%;
    }
    gap: 8px;
  }

  &__submission {
    // width: 35%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__status {
    // width: 30%;
  }
  &__notes {
    // width: 35%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 16px;
    padding-top: 8px;
    textarea {
      height: 100%;
      width: 100%;
    }
  }
  &__footer {
    grid-column: span 3 / span 3;
    display: flex;
    justify-content: end;
  }
}

.scoring{
  &__wrapper {
    display: grid;
    grid-template-rows: 14% repeat(4, 18%);
    align-items: center;
    p {
      line-height: 1;
    }
}
}

.p-inputgroup {
  display: grid;
  grid-template-columns: 25% 75%;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 35% 65%;
  }
  align-items: center;
  button {
    color: #000;
    cursor: default;
    &:hover {
      background: transparent;
      color: #000;
    }
  }
  &.checkbox-group {
    grid-template-columns: 10% 90%;
  }

  &.notes-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    row-gap: 4px;
  }
  .p-inputtext-fluid {
    width: 100% !important;
  }
}
</style>
