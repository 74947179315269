<template>
  <Card class="prizes-card" bgColor="#F5F5F5">
    <h2 class="prizes-card__title">Prizes*</h2>
    <h3 class="prizes-card__subtitle">Zone Prizes</h3>
    <div class="prizes-card__wrapper">
      <template v-for="prize in prizes" :key="prize">
        <Card class="prize-card" hasShadow>
          <span class="prize-card__place">{{ prize.place.toUpperCase() }}</span>
          <span class="prize-card__reward">
            <sup>$</sup>{{ prize.reward.toLocaleString('en-US') }}
          </span>
          <p class="prize-card__credits-title">
            {{variant === 'acura' ? 'Cose' : 'Cosl'}} Sales Credits
          </p>
          <span class="prize-card__credits-value" aria-hidden>
            {{ prize.salesCredits }}
          </span>
          <span hidden>
            {{ prize.ariaLabelCredits }}
          </span>
        </Card>
      </template>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.prizes-card {
  padding: 58px 8.65vw;
  max-width: 95vw;
  font-family: var(--font-secondary);
  &__title,
  &__subtitle {
    font-weight: 700;
    line-height: normal;
    font-family: var(--font-primary);
  }
  &__title {
    font-weight: 700;
    font-size: clamp(3rem, 2.7131rem + 1.0724vw, 4rem);
  }
  &__subtitle {
    font-weight: 600;
    color: #4291d1;
    font-size: clamp(2.25rem, 2.0707rem + 0.6702vw, 2.875rem);
  }
}
.prizes-card__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  :not(:first-child) {
    width: 90%;
  }
}

.prizes-card__wrapper > :nth-child(1) {
  // border: 5px solid #4291D1;
  // box-shadow: 0px 0px 21px #4291D1;

  @media screen and (max-width: 600px) {
    margin-top: 32px;
  }
}

.prize-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-secondary);
  padding: 16px 0;
  aspect-ratio: 1;
  line-height: normal;
  &__reward {
    color: #4291d1;
  }
  &__place {
    font-size: clamp(2.25rem, 1.9273rem + 1.2064vw, 3.375rem);
    line-height: normal;
  }
  &__reward {
    font-size: clamp(5rem, 4.3125rem + 3.4375vw, 8.4375rem);
    // font-size: clamp(7.25rem, 6.9093rem + 1.2735vw, 8.4375rem);
    display: flex;
    align-items: self-start;
    justify-content: center;
    sup {
      font-size: 60%;
    }
  }
  &__credits-title {
    font-size: 25px;
    text-transform: uppercase;
    line-height: 1.6;
  }
  &__credits-value {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .prizes-card {
    padding: 16px 5% 40px;
  }
  .prizes-card__wrapper {
    display: grid;
    justify-content: unset;
  }
}
</style>

<script setup>
import { ref } from 'vue';
import Card from '@/components/TheCard.vue';
import { useRoute } from 'vue-router';
import useVariant from '@/utils/useVariant';

const route = useRoute();
const variant = useVariant(route.params);

const prizes = ref([
  {
    place: '1st Place',
    reward: 1000,
    salesCredits: 5,
    ariaLabelCredits: 'five',
  },
  {
    place: '2nd Place',
    reward: 500,
    salesCredits: 3,
    ariaLabelCredits: 'three',
  },
  {
    place: '3rd Place',
    reward: 250,
    salesCredits: 1,
    ariaLabelCredits: 'one',
  },
]);
</script>
