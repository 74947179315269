/* eslint-disable */
const paramsSerializer = (objects) => {
const filteredEntries = Object.entries(objects).filter(([key, value]) => {
  if (value === null) return false;
  if (value === '') return false;
  if (Array.isArray(value) && value.length === 0) return false;
  return true;
});

if (filteredEntries.length === 0) {
  return '';
}

const queryString = filteredEntries
  .map(([key, value]) => {
    if(Array.isArray(value)) {
      let str = value.map((item) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
      });
      return str.join('&');
    } else {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  })
  .join('&');

return `?${queryString}`;
};

export default paramsSerializer;
