import {
  computed,
} from 'vue';

export default function useDynamicStyles(variant) {
  const fonts = {
    acura: ['Avenir', 'Acura Bespoke', 'Avenir Book'],
    honda: ['Myriad Pro'],
  };

  const listColors = {
    acura: { primary: '#000' },
    honda: { primary: '#003C6F' },
  };

  return computed(() => ({
    '--font-primary': fonts[variant][0],
    '--font-secondary': fonts[variant][1] || fonts[variant][0],
    '--font-tertiary': fonts[variant][2] || fonts[variant][0],
    '--list-primary': listColors[variant].primary,
  }));
}
