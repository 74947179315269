<template>
  <Card class="prizes-card" bgColor="#F5F5F5">
    <h2 class="prizes-card__title">Final Live National Competition Prizes**</h2>
    <div class="prizes-card__wrapper">
      <template v-for="prize in prizes" :key="prize">
        <Card class="prize-card">
          <span class="prize-card__place">{{ prize.place }}</span>
          <span class="prize-card__reward">
            <sup>$</sup>{{ prize.reward.toLocaleString('en-US') }}
          </span>
          <p class="prize-card__credits-title">
            {{ siteVariable === 'Acura' ? 'Cose' : 'Cosl' }} Sales Credits
            <span class="prize-card__credits-value">{{ prize.salesCredits }}</span>
          </p>
          <template v-if="prize.motocompacto">
            <span class="prize-card__motocompacto-plus"> + </span>
            <br />
            <span class="prize-card__motocompacto-label">Motocompacto***</span>
          </template>
        </Card>
      </template>
    </div>
    <p class="prizes-card__other-prizes">
      <span>5-12th Place: $500</span> <br />
      {{ siteVariable === 'Acura' ? 'COSE' : 'COSL' }} Sales Credits - 4
    </p>
    <div class="prizes-card__observations">
      <p>
        *Cash Prize awards paid directly to Sales Consultants are taxable to the individual
        participant. Recipients of such award payments should contact their tax advisor for advice.
        {{ siteVariable }} Division will report awards of $600 or higher to the IRS and provide a
        1099 to the recipient. Awards of less than $600 will not receive a 1099.
      </p>
      <p>
        **Must travel to and compete in National Competition to receive national prizes.
        Participants for the National Competition will be chosen from ALL valid video submissions
        regardless of Zone.
      </p>
      <p>***Motocompactos will be shipped directly to the Zone Office.</p>

      <p>
        ****Sales Consultant must be employed and active at {{ siteVariable }} dealership at the
        time of the competition.
      </p>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.prizes-card {
  padding: 58px 22px;
  max-width: 95vw;
  font-family: var(--font-primary);
  &__title {
    font-size: clamp(3rem, 2.7131rem + 1.0724vw, 4rem);
    font-weight: 700;
    line-height: normal;
  }
  &__other-prizes {
    line-height: normal;
    margin-top: 2.5vw;
    font-size: clamp(1.3125rem, 1.1153rem + 0.7373vw, 2rem);
    span {
      font-size: 190%;
    }
    @media screen and (max-width: 767px) {
      margin-top: 3rem;
    }
  }
  &__observations {
    text-align: left;
    margin-top: 30px;
    font-size: clamp(0.9375rem, 0.7044rem + 0.8713vw, 1.75rem);
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 28px;
    font-family: var(--font-primary);
    font-weight: 300;
  }
}
.prizes-card__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.prize-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-secondary);
  padding: 16px 0;
  aspect-ratio: 1;
  box-shadow: 0px 10px 15px #0000002b;
  max-width: 500px;
  &:not(:last-child) {
    position: relative;
    &::after {
      content: '';
      width: 30%;
      height: 30%;
      max-width: 130px;
      max-height: 130px;
      display: block;
      // background: #F3F4F8;
      position: absolute;
      top: 0px;
      right: 0px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 96px;
      background: url('@/assets/img/motocompacto_prize.png');
      background-size: cover;
      background-position: no-repeat;
    }
  }
  &__reward,
  &__motocompacto-label {
    color: #4291d1;
  }
  &__place {
    font-size: clamp(1.5625rem, 1.5087rem + 0.2011vw, 1.75rem);
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  &__reward {
    font-size: clamp(4.375rem, 4rem + 1.875vw, 6.25rem);
    line-height: normal;
    sup {
      font-size: 60%;
    }
  }
  &__credits-title {
    text-transform: uppercase;
    color: black;
    line-height: normal;
    font-size: 18px;
  }
  &__credits-value {
    font-size: 28px;
    font-weight: 700;
  }
  &__motocompacto-plus {
    font-size: 18px;
  }
  &__motocompacto-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.4125rem + 0.4375vw, 1.9375rem);
  }
}

@media screen and (max-width: 767px) {
  .prize-card:first-child {
    margin-top: 32px;
  }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import Card from '@/components/TheCard.vue';

const route = useRoute();

const prizes = ref([
  {
    place: '1st Place',
    reward: 5000,
    salesCredits: 10,
    motocompacto: true,
  },
  {
    place: '2nd Place',
    reward: 3000,
    salesCredits: 8,
    motocompacto: true,
  },
  {
    place: '3rd Place',
    reward: 2000,
    salesCredits: 6,
    motocompacto: true,
  },
  {
    place: '4th Place',
    reward: 1000,
    salesCredits: 4,
    motocompacto: false,
  },
]);

const siteVariable = computed(() => {
  const { brand } = route.params;
  return !brand || brand.toLowerCase() === 'prologue' ? 'Honda' : 'Acura';
});
</script>
