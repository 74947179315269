<template>
  <div class="home__wrapper" :style="dynamicStyles">
    <IntroTitle></IntroTitle>
    <IntroSection />
    <PrizesList />
    <NationalCompetitionPrizes />
    <FinalLiveCompetitionPrizes />
    <section>
      <p class="dates-title">Dates</p>
      <ul class="timeline">
        <li
          :key="`timeline-${date}`"
          v-for="{ date, label, highlight } in dates"
          class="timeline__item">
          <button class="timeline__item__button">
            {{ date[0] }}
            <p :style="date[1].length <= 2 ? 'font-size: 65px': 'font-size: 52px'">{{ date[1] }}</p>
          </button>
          <span
            class="timeline__item__label"
            :style="highlight ? {color: '#E82C2A'}: {color: '#000'}">
            {{ label }}
          </span>
        </li>
      </ul>
    </section>
    <template v-if="disabledContest">
      <GlowButton
        v-tooltip.bottom="{
            value: 'The deadline for submitting entries has passed, the competition is now closed.',
            pt: {
                text: '!bg-primary !text-primary-contrast !font-medium'
            }
        }"
        class="get-started-btn"
        @click="openModal"
        disabled
        aria-label="Select to open submission modal">
        Get Started
      </GlowButton>
    </template>
    <template v-else>
      <GlowButton
        class="get-started-btn"
        @click="openModal"
        aria-label="Select to open submission modal">
        Get Started
      </GlowButton>
    </template>

    <TheModal class="modal-wrapper" v-model="isModalOpen">
      <SubmissionModal @closeModal="closeModal"/>
    </TheModal>
  </div>
</template>

<script setup>
import TheModal from '@/components/TheModal.vue';
import IntroTitle from '@/components/intro/IntroTitle.vue';
import IntroSection from '@/components/intro/IntroSection.vue';
import PrizesList from '@/components/home/PrizesList.vue';
import NationalCompetitionPrizes from '@/components/home/NationalCompetitionPrizes.vue';
import FinalLiveCompetitionPrizes from '@/components/home/FinalLiveCompetitionPrizes.vue';
import GlowButton from '@/components/GlowButton.vue';
import SubmissionModal from '@/components/modals/SubmissionModal.vue';
import useDynamicStyles from '@/utils/BrandFonts';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import useVariant from '@/utils/useVariant';

const route = useRoute();

const variant = useVariant(route.params);
const dynamicStyles = useDynamicStyles(variant);
const disabledContest = computed(() => {
  const parsedDisable = JSON.parse(route.query.disabled || 'false');

  return parsedDisable;
});

const dates = [
  { date: ['AUG', '5'], label: 'Website Submissions Open', highlight: false },
  { date: ['AUG', '26'], label: 'Submission Deadline', highlight: false },
  { date: ['AUG', '30'], label: 'Submission Deadline Has Been Extended', highlight: true },
  { date: ['SEPT', '16'], label: 'Announcements of Zone Winners & National Finalists', highlight: false },
  { date: ['OCT', variant === 'acura' ? '14-16' : '16-18'], label: 'Final Live Competition in Las Vegas', highlight: false },
];

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>

<style lang="scss" scoped>
.home__wrapper {
  display: grid;
  gap: 60px;

  .dates-title {
    font-size: clamp(3rem, 2.7131rem + 1.0724vw, 4rem);
    font-family: var(--font-primary);
    font-weight: 700;
    margin-bottom: 74px;
  }
}
.timeline {
  display: grid;
  // justify-content: space-between;
  // align-items: center;
  grid-template-columns: repeat(5, 1fr);
  // grid-gap: 150px;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  position: relative;
  font-family: var(--font-primary);
  @media screen and (min-width: 1440px) {
    width: 73%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-grow: 1;

    &:nth-child(2) {
      .timeline__item__button {
        border: 15px solid #E82C2A;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -2%;
          left: 45%;
          height: 105%;
          width: 15px;
          background: #E82C2A;
          transform: rotate(-45deg);
        }
      }
    }
    &:nth-child(3) {
      .timeline__item__button {
        background: #E82C2A;
        border-color: #E82C2A;
      }
    }

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: calc(26% - -1vw);
      left: -50%;
      width: 100%;
      height: 3px;
      background-color: #90caf9;
      z-index: -1;
    }
    &__button {
      background-color: #000;
      color: #fff;
      border: 4px solid #90caf9;
      border-radius: 50%;
      width: 186px;
      height: 186px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      margin-bottom: 8px;
      position: relative;
      z-index: 1;
      font-family: var(--font-secondary);
    }

    &__label {
      font-size: 24px;
      margin-top: 8px;
      padding: 0 2vw;
      @media screen and (max-width: 767px) {
        padding: 0 28vw;
      }
      font-family: var(--font-secondary);
    }
  }
}

.get-started-btn {
  width: 300px;
  margin-bottom: 32px;
}

@media screen and (max-width: 1044px) {
  .dates-title {
    margin-bottom: 14px;
  }
  .timeline {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 100px;
    &__item {
      &:not(:first-child)::before {
        content: '';
        position: absolute;
        top: -32%;
        left: 50%;
        width: 3px;
        height: 100%;
        background-color: #90caf9;
        z-index: -1;
      }
      &__button {
        width: 187px;
        height: 187px;
      }
    }
  }
}
</style>
