/* eslint-disable */

import {
  createApp,
} from 'vue';
import Toast from 'vue-toastification';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import Tooltip from 'primevue/tooltip';
import * as directives from 'vuetify/directives';
import PrimeVue from "primevue/config";
import Aura from '@primevue/themes/aura';
import { createMetaManager, defaultConfig } from 'vue-meta';

import router from './router';
import App from './App.vue';

import '@/assets/style/index.scss';
import 'vue-toastification/dist/index.css';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          '--v-border-color': '#4291D1',
        }
      }
    }
  }
});

createApp(App)
  .use(vuetify)
  .use(PrimeVue, {
    theme: {
        preset: Aura
    }
  })
  .use(router)
  .use(Toast, {})
  .use(createMetaManager(false, {
    ...defaultConfig,
    robots: {
      tag: 'meta',
    },
    meta: { tag: 'meta', nameless: true },
  }))
  .directive('tooltip', Tooltip)
  .mount('#app');
