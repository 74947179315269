<template>
    <button class="get-started-btn"><slot /></button>
</template>

<style scoped lang="scss">
.get-started-btn {
  border: 3px solid #000;
  color: #000;
  // width: 100%;
  height: 71px;
  background: white;
  border-radius: 60px;
  margin: 0 auto;
  padding: 0 32px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--font-primary);
  font-weight: 300;
  font-size: 31px;
  // margin-bottom: 32px;
  &:hover {
    border: 3px solid #4291d1;
    color: #4291d1;
    box-shadow: 0px 0px 10px 2px rgba(66, 145, 209, 1);
  }
  &:focus-visible {
    outline-offset: 5px;
  }
}
</style>
