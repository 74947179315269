import axios from 'axios';

import Auth from './auth';
import Data from './data';
import RegistrationForm from './registrationForm';

const success = (response) => response;
const failure = (error) => Promise.reject(error);

class ApiManager {
  constructor() {
    this.requiresAuth = false;
    // Core api for project
    this.coreObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_APP_URL,
    });

    this.coreObject.interceptors.response.use(success, failure);

    this.auth = new Auth(this.coreObject);
    this.data = new Data(this.coreObject);
    this.registrationForm = new RegistrationForm(this.coreObject);
  }
}

export default new ApiManager();
