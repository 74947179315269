<template>
  <section class="step">
    <p class="step__title">Step <span class="step__index">1</span></p>
    <div class="step__body">
      <p class="step__introduction">Before you submit, make sure your video follows the rules:</p>
      <ul class="step__instructions-list">
        <li>
          Choose {{ variables[variant].dealershipName }} in your dealership. If unavailable,
          {{ variables[variant].alternativeStepA }}.
        </li>
        <li>Do <b>NOT</b> record a full vehicle presentation.</li>
        <li>
          <p>
            {{ variables[variant].stepCDescription }}
          </p>
          <p class="step__instructions-list__sublist__title"><b>FOCUS FEATURES</b></p>
          <ul class="step__instructions-list__sublist">
            <li><b>EVeryday:</b> Charging Packages</li>
            <li><b>EVolve:</b> One pedal driving/regenerative braking</li>
            <li><b>EVenture:</b> Google built-in</li>
          </ul>
          <p class="step__instructions-list__sublist__title">
            <b>{{ variables[variant].stepCProfileTitle }}</b>
          </p>
          <ul class="step__instructions-list__sublist">
            <li>Bob, 47, Certified Public Accountant</li>
            <li>Married to Susan, a Real Estate Agent</li>
            <li>They jointly own their home</li>
            <li>Two children – a daughter in high school and a son in college a few hours away</li>
            <li>
              Bob drives 8 miles into the city for work. They take longer trips to see their son
            </li>
            <li>Coaches his daughter’s Lacrosse team</li>
            <li>First time EV buyer and not very knowledgeable or technical</li>
          </ul>
        </li>
        <li>Your video must be 2-3 minutes in length.*</li>
        <li>At the beginning of your video, state your name and the name of your dealership.</li>
        <li>
          <b>No editing, special effects or music is permitted.</b> Video stopping and starting or
          fade transitions are acceptable.*
        </li>
        <li>Only one video per contestant.</li>
      </ul>
      <p class="step__observation">
        *Judges reserve the right to disqualify videos over or under the time requirement or those
        that do not otherwise follow the guidelines.
      </p>
      <GlowButton
        class="step__button"
        @click="downloadRules()"
        aria-label="Select to download the rules PDF"
      >
        <p class="step__button-label">Download Official Rules PDF</p>
      </GlowButton>
    </div>
  </section>
  <section class="step">
    <p class="step__title">Step <span class="step__index">2</span></p>
    <div>
      <p class="step__introduction">Upload your video to YouTube</p>
      <ul class="step__instructions-list">
        <li>Record and save your video using your smartphone.</li>
        <li>
          Upload your video to a personal or dealership YouTube account. Under visibility, select
          Unlisted (not private).
        </li>
        <li class="buttons-wrapper">
          Copy the provided video link by selecting the
          <button
            class="custom-button share-btn"
            disabled
            aria-label="Visual example of what the Share button looks like">
            <img src="@/assets/img/share.svg" alt="Share Icon" style="width: 32px;"/>
            Share
          </button>
          button and then selecting the
          <button
            class="custom-button
            copy-btn"
            disabled
            aria-label="Visual example of what the copy button looks like">
            Copy
          </button>
          button.
        </li>
        <li>
          Complete the submission form in the next step. There will be a spot for you to paste the
          YouTube video link in the form.
        </li>
      </ul>
      <div class="step__additional-info">
        <p><b>For help, please contact the contest Concierge:</b></p>
        <p>9:00 am - 5:00 pm EDT, Monday - Friday</p>
        <p>
          <a href="tel:8556538061" aria-label="Select to call the PHQ team on mobile devices"
            ><b><u>(855) 653-8061</u></b></a
          >
        </p>
        <a
          href="mailto:info@EVolutioncomp.com?subject=Evolution Competition Program Inquiry"
          aria-label="Select to email the PHQ team"
        >
          <b><u>info@EVolutioncomp.com</u></b></a
        >
      </div>
    </div>
  </section>
  <section class="step">
    <p class="step__title">Step <span class="step__index">3</span></p>
    <div>
      <p class="step__introduction">Submit your video</p>
      <p class="step__subtitle">All fields are required</p>
      <form class="submission-form" @submit.prevent="submitForm">
        <TheInput
          name="firstname"
          label="FIRST NAME*"
          :maxLength="50"
          placeholder="First Name"
          :dirty="v$.firstName.$dirty"
          :errorMessage="v$.firstName.$errors[0]?.$message"
          v-model:value="state.firstName"
        />
        <TheInput
          name="lastname"
          label="LAST NAME*"
          :maxLength="100"
          placeholder="Last name"
          :dirty="v$.lastName.$dirty"
          :errorMessage="v$.lastName.$errors[0]?.$message"
          v-model:value="state.lastName"
        />
        <TheInput
          name="phone"
          label="MOBILE*"
          placeholder="(000) 000-0000"
          mask="(000) 000-0000"
          maxLength="14"
          :dirty="v$.phone.$dirty"
          :errorMessage="v$.phone.$errors[0]?.$message"
          v-model:value="state.phone"
        />
        <TheInput
          name="email"
          label="EMAIL*"
          placeholder="Email"
          :dirty="v$.email.$dirty"
          :errorMessage="v$.email.$errors[0]?.$message"
          v-model:value="state.email"
        />
        <TheInput
          name="dptsid"
          label="DPTS ID*"
          class="full-row"
          placeholder="Enter Your DPTS ID"
          :dirty="v$.dptsid.$dirty"
          :maxLength="7"
          :errorMessage="v$.dptsid.$errors[0]?.$message"
          v-model:value="state.dptsid"
        />
        <div class="dptsid-conditional-fields full-row">
          <span class="loader" v-if="validatingDPTSID"></span>
          <TheSelect
            v-model="state.zoneNumber"
            class="full-row"
            required
            label="ZONE NUMBER"
            inlineOption="Select Zone"
            :disabled="validatingDPTSID"
            :options="zoneNumberOptions"
            :dirty="v$.zoneNumber.$dirty"
            :error-message="v$.zoneNumber.$errors[0]?.$message"
          />
          <TheSelect
            v-model="state.districtLetter"
            class="full-row"
            required
            label="DISTRICT LETTER"
            inlineOption="Select District"
            :disabled="validatingDPTSID"
            :options="districtLetterOptions"
            :dirty="v$.districtLetter.$dirty"
            :error-message="v$.districtLetter.$errors[0]?.$message"
          />
          <NewSelect
              v-model="state.dealershipName"
              class="full-row"
              fluid
              filter
              fixedLabel
              :options="dealershipOptions"
              inlineOption="Select Dealership"
              label="DEALERSHIP NAME*"
              id="dealershipSelect"
              :dirty="v$.dealershipName.$dirty"
              :errorMessage="v$.dealershipName.$errors[0]?.$message"
              :maxLength="150"
              :disabled="validatingDPTSID"
            />
        </div>
        <div class="full-row">
          <p><b>YOUTUBE VIDEO LINK*</b></p>
          <TheInput
            name="youtubeLink"
            label="Enter the Unlisted or Public video link to your video here:"
            placeholder="Enter Your Video Link"
            :maxLength="200"
            :dirty="v$.youtubeLink.$dirty"
            :errorMessage="v$.youtubeLink.$errors[0]?.$message"
            v-model:value="state.youtubeLink"
          />
        </div>
        <p class="full-row">
          All information provided by the participant will be used by AHM and its agency for
          communication and video evaluation purposes regarding this contest and will not be shared
          with any outside third parties. Videos submitted to the competition as described in the
          rules become the sole and exclusive property of American Honda Motor Co., Inc. (including
          any and all right, title, and interest to copyrights in such a video) and may be used
          (including your name, image, and likeness) for any purpose, including training or
          promotion/advertising, in perpetuity, without any consideration or payment to you.
        </p>
        <div class="full-row">
          <TheCheckbox
            v-model="state.termsAndConditions"
            id="submission-optin"
            text="I AGREE TO THE CONTEST TERMS AND CONDITIONS* "
            :dirty="v$.termsAndConditions.$dirty"
            :error-message="v$.termsAndConditions.$errors[0]?.$message"
          />
          <a
            class="terms-conditions-link"
            @click="downloadRules()"
            @keypress.enter="downloadRules()"
            tabindex="0"
          >
            Read full terms and conditions.
          </a>
        </div>
        <GlowButton
          class="full-row submit-btn"
          type="submit"
          :disabled="isSubmitting"
          :aria-label="!isSubmitting ? 'Select to submit your entry' : 'Submitting your entry'"
        >
          <template v-if="!isSubmitting"> Submit </template>
          <template v-else> Loading </template>
        </GlowButton>
      </form>
    </div>
  </section>
</template>

<style scoped lang="scss">
.step {
  line-height: normal;
  text-align: left;
  padding-left: 4px;
  font-family: var(--font-primary);
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  &__title {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: clamp(2.625rem, 1.8182rem + 3.0161vw, 5.4375rem);
    font-weight: 300;
    &::after {
      flex: 1;
      height: 2px;
      background: #4291d1;
      display: block;
      content: '';
      // position: absolute;
      // right: 0;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  &__index {
    line-height: 80%;
    border: 4px solid #4291d1;
    border-radius: 50%;
    aspect-ratio: 1;
    width: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__body {
    padding-right: 32px;
  }

  &__introduction {
    font-size: clamp(1.25rem, 0.8556rem + 1.4745vw, 2.625rem);
    margin-bottom: 24px;
  }
  &__subtitle {
    font-size: clamp(1.125rem, 1.0385rem + 0.3846vw, 1.5rem);
    margin-bottom: 32px;
  }
  &__observation {
    margin-top: 32px;
    font-family: 'Avenir Book';
    font-style: italic;
    font-size: clamp(0.875rem, 0.7674rem + 0.4021vw, 1.25rem);
  }
  &__button {
    display: flex;
    align-items: center;
    margin: 32px auto;
    &-label {
      font-size: clamp(1.0625rem, 0.8115rem + 0.9383vw, 1.9375rem);
    }
  }

  &__instructions-list {
    counter-reset: custom-counter; /* Initialize the counter */
    list-style: none; /* Remove default list styles */
    font-size: clamp(1.125rem, 1.0174rem + 0.4021vw, 1.5rem);
    margin-top: 8px;
    > li {
      counter-increment: custom-counter; /* Increment the counter for each list item */
      position: relative; /* Needed for the pseudo-element positioning */
      padding-left: 2em; /* Adjust padding to make space for the custom marker */
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      &::before {
        content: counter(custom-counter, upper-latin); /* Use the counter with upper Latin */
        position: absolute;
        top: -0.2em;
        left: 0; /* Position it to the left of the list item */
        width: 1.5em;
        height: 1.5em;
        line-height: 1.6em;
        border-radius: 50%; /* Make it a circle */
        background-color: var(--list-primary); /* Black background */
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 1);
        color: white; /* White text */
        text-align: center; /* Center the text inside the circle */
      }
    }
    &__sublist {
      list-style: disc;
      padding-left: 2rem;
      line-height: 1.5;
    }
    &__sublist__title {
      margin-top: 32px;
    }
  }

  &__additional-info {
    margin-top: 120px;
    font-size: clamp(1.125rem, 1.0174rem + 0.4021vw, 1.5rem);
    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    @media screen and (max-width: 700px) {
      margin-top: 60px;
    }
  }
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .custom-button {
    border-radius: 32px;
    min-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    &.share-btn {
      background: #FFF;
      border: 1px solid #000;
      color: #000;
    }

    &.copy-btn {
      min-width: 80px;
      background: #065FD4;
      color: #FFF;
      border: none;
    }
  }
}

.submission-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 32px;
  background: white;
  border-radius: 30px;
  padding: 34px 90px;
  margin-right: 68px;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-right: 24px;

    // grid-template-columns: repeat(1, 1fr);
  }
  .full-row {
    grid-column: span 2 / span 2;
  }

  .dptsid-conditional-fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 32px;
    .loading {
      position: absolute;
    }
    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: column;
      // padding: 20px;
    }
  }
  .terms-conditions-link {
    color: #4291d1;
    text-decoration: underline;
    cursor: pointer;

    @media screen and (min-width: 420px) {
      margin-left: 50px;
    }
  }
  .submit-btn {
    margin: 0 auto 32px;
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #ffffff6b;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  border-radius: 8px;
}

.loader:after {
  content: '';
  width: 48px;
  height: 48px;
  border: 5px solid #767676;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script setup>
import GlowButton from '@/components/GlowButton.vue';
import TheInput from '@/components/TheInput.vue';
import TheCheckbox from '@/components/TheCheckbox.vue';
import TheSelect from '@/components/TheSelect.vue';
import NewSelect from '@/components/NewSelect.vue';
import api from '@/api';
import { useRoute } from 'vue-router';
import {
  computed,
  onMounted,
  ref,
  watch,
  defineEmits,
} from 'vue';
import useValidate from '@vuelidate/core';
import {
  helpers,
  sameAs,
} from '@vuelidate/validators';
import useVariant from '@/utils/useVariant';
import { useToast } from 'vue-toastification';
import { unmaskPhoneNumber } from '@/utils/format';
import {
  scrollToFirstError,
  requiredValidator,
  DPTSIDValidator,
  phoneValidator,
  emailValidator,
  youtubeValidator,
} from '@/utils/form';

const route = useRoute();
const toast = useToast();
const variant = useVariant(route.params);

const zoneNumberOptions = ref([]);
const districtLetterOptions = ref([]);
const dealershipOptions = ref([]);

const variables = {
  acura: {
    dealershipName: 'an Acura ZDX',
    alternativeStepA: 'use an RDX',
    stepCDescription: `
        During your walkaround create a story connecting the CLIENT’S PROFILE to the Acura EV
        app and one additional FOCUS FEATURE (see below).
    `,
    stepCProfileTitle: 'CLIENT PROFILE',
  },
  honda: {
    dealershipName: 'a Honda Prologue',
    alternativeStepA: 'use an Accord with Google built-in',
    stepCDescription: `
        During your walkaround create a story connecting the CUSTOMER’S PROFILE to HondaLink and
        one additional FOCUS FEATURE (see below).
    `,
    stepCProfileTitle: 'CUSTOMER PROFILE',
  },
};

const handleFilesPath = (BASE_URL) => {
  const path = BASE_URL !== '/' ? BASE_URL : '';
  return path;
};

const downloadRules = () => {
  const titleCase = (text) => text.replace(/(^|\s)\S/g, (t) => t.toUpperCase());
  const fileName = `${handleFilesPath(process.env.VUE_APP_BASE_URL)}/documents/${titleCase(
    variant,
  )}_Evolution_Competition_Rules.pdf`;

  const link = document.createElement('a');

  link.href = fileName;
  link.target = '_blank';
  // link.download = `${titleCase(variant)}_Evolution_Competition_Rules.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const emit = defineEmits(['closeModal']);

const state = ref({
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  dptsid: '',
  zoneNumber: '',
  districtLetter: '',
  dealershipName: '',
  youtubeLink: '',
  termsAndConditions: false,
});

const rules = computed(() => ({
  firstName: { requiredValidator },
  lastName: { requiredValidator },
  phone: { requiredValidator, phoneValidator },
  email: { requiredValidator, emailValidator },
  dptsid: {
    requiredValidator,
    DPTSIDValidator,
  },
  zoneNumber: { requiredValidator },
  districtLetter: { requiredValidator },
  dealershipName: { requiredValidator },
  youtubeLink: { requiredValidator, youtubeValidator },
  termsAndConditions: {
    sameAs: helpers.withMessage('Please agree to the terms and conditions.', sameAs(true)),
  },
}));

const v$ = useValidate(rules, state.value, { $autoDirty: true });

const fetchZoneCodes = async () => {
  try {
    const { data } = await api.data.getZoneCodes(variant);
    zoneNumberOptions.value = data;
  } catch (e) {
    toast.error('An error ocurred while fetching Zone Codes, please try again.');
  }
};

const fetchDistrictLetters = async () => {
  try {
    const { data } = await api.data.getDistrictLetters(variant);
    districtLetterOptions.value = data;
  } catch (e) {
    toast.error('An error ocurred while fetching District Letters, please try again.');
  }
};

const fetchDealerships = async () => {
  try {
    const { data } = await api.data.getDealerships(variant);
    dealershipOptions.value = data;
  } catch (e) {
    toast.error('An error ocurred while fetching Dealerships, please try again.');
  }
};

const validatingDPTSID = ref(false);

const validateDPTSID = async (dptsid) => {
  validatingDPTSID.value = true;
  try {
    const { data, status } = await api.data.validateDPTSId(dptsid, variant);
    if (status === 204) {
      return;
    }
    const { dealerName, zoneCode, districtCode } = data;

    state.value.dealershipName = dealerName;
    state.value.zoneNumber = zoneCode;
    state.value.districtLetter = districtCode;
  } catch (e) {
    toast.error('An error ocurred while validating DPTS ID, please try again.');
  } finally {
    validatingDPTSID.value = false;
  }
};

const isSubmitting = ref(false);

const postSubmission = async (data) => {
  try {
    await api.registrationForm.postSubmit(data, variant);
    toast.success(
      'Thank you for your submission! Watch your email for an announcement of the zone winners and national finalists September 16th.',
      { timeout: 20000 },
    );
    emit('closeModal');
  } catch (e) {
    toast.error(e.response.data.message || 'An error ocurred while submitting, please try again.', {
      timeout: 20000,
    });
  } finally {
    isSubmitting.value = false;
  }
};

const submitForm = async () => {
  isSubmitting.value = true;
  await v$.value.$validate();
  state.value.phone = unmaskPhoneNumber(state.value.phone);
  if (!v$.value.$error) {
    postSubmission(state.value);
  } else {
    isSubmitting.value = false;
    scrollToFirstError(document);
    const toastMessage = v$.value.$errors[0].$validator === 'requiredValidator' ? 'Please provide values for all required fields.' : v$.value.$errors[0].$message;
    toast.error(toastMessage);
  }
};

watch(
  () => state.value.dptsid,
  () => {
    if (state.value.dptsid.length === 7) {
      validateDPTSID(state.value.dptsid);
    }
  },
);

onMounted(() => {
  fetchZoneCodes();
  fetchDistrictLetters();
  fetchDealerships();
});
</script>
