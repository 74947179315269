<script setup>
import { reactive, computed, defineProps } from 'vue';
import useValidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import api from '@/api';
import TheFieldset from './TheFieldset.vue';
import TheTextfield from './TheInput.vue';
import TheButton from './GlowButton.vue';

const toast = useToast();

const state = reactive({
  key: '',
});

const requiredValidator = helpers.withMessage('Field is required', required);

const rules = computed(() => ({
  key: {
    requiredValidator,
  },
}));

defineProps({
  loading: Boolean,
  title: { type: String, required: true },
  legend: { type: String, required: true },
});

const v$ = useValidate(rules, state, { $autoDirty: true });

const emit = defineEmits(['enableCheckIn']);

async function submitForm() {
  await v$.value.$validate();
  if (!v$.value.$error) {
    try {
      await api.auth.postLogin(state.key);
      emit('enableCheckIn', state.key);
    } catch ({ response }) {
      toast.error(response.data.message || 'An error ocurred while trying to login. Please try again.');
    }
  } else {
    toast.error('Please provide a valid key.');
  }
}
</script>
<template>
  <div class="login__wrapper">
    <!-- <div class="col-span-2 xl:col-span-3"> -->
    <TheFieldset :legend="legend" class="lg:grid-cols-4 gap-4">
      <form @submit.prevent="submitForm" class="">
        <TheTextfield
          type="text"
          name="key"
          label="KEY*"
          placeholder="Enter Key here*"
          maxLength="25"
          :dirty="v$.key.$dirty"
          :errorMessage="v$.key.$errors[0]?.$message"
          v-model:value="state.key"
          class="login__key-field"
          autocomplete="admin-key"
        />
        <TheButton type="submit" class="border-2" label="" :loading="loading"> Login </TheButton>
      </form>
    </TheFieldset>
    <!-- </div> -->
  </div>
</template>

<style scoped lang="scss">
.login {
  &__wrapper {
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    form {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
      align-items: center;
    }
  }

  &__key-field {
    grid-column: span 3 / span 3;
  }
}
</style>
