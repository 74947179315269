<script setup>
const props = defineProps({
  legend: { String, required: true },
});
</script>
<template>
  <fieldset class="fieldset__wrapper">
    <legend class="fieldset__legend">{{ props.legend }}</legend>
    <slot></slot>
  </fieldset>
</template>

<style scoped lang="scss">
.fieldset {
  &__wrapper {
    border: 1px solid #CECECE;
    display: grid;
    padding: 10px;
    text-align: left;
  }
  &__legend {
    padding: 0 4px;
    font-family: var(--font-primary);
    width: max-content;
  }
}
</style>
