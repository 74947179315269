/* receives a masked phoneNumber (111) 111-1111 and returns 1111111111; */
const unmaskPhoneNumber = (maskedNumber) => maskedNumber.replace(/\D/g, '');

/* receives a date as string, such as 2024-08-07T13:57:08.572Z and converts into 08/07/2024 */
const toLocaleDateString = (dateString) => {
  const dateToParse = new Date(dateString);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return dateToParse.toLocaleDateString('en-US', options);
};

export {
  unmaskPhoneNumber,
  toLocaleDateString,
};
