import paramsSerializer from '@/utils/paramsSerializer';
import {
  baseCRUDAPI,
} from './base';

export default class Data extends baseCRUDAPI {
  BrandEnum = Object.freeze({
    acura: 1,
    honda: 2,
  });

  constructor(axiosObject) {
    super(axiosObject, 'Data');
  }

  buildQueryString(brand = '', submissionsOnly = false) {
    const brandObj = {};
    if (brand) {
      brandObj.registrationType = this.BrandEnum[brand];
    }

    if (submissionsOnly) {
      brandObj.submissionsOnly = true;
    }

    const queryString = paramsSerializer(brandObj);
    return queryString;
  }

  async getZoneCodes(brand, submissionsOnly) {
    const queryString = this.buildQueryString(brand, submissionsOnly);
    return this.axiosObject.get(`${this.apiBaseName}/zone-codes${queryString}`);
  }

  async getDistrictLetters(brand, submissionsOnly) {
    const queryString = this.buildQueryString(brand, submissionsOnly);
    return this.axiosObject.get(`${this.apiBaseName}/district-letters${queryString}`);
  }

  async getDealerships(brand, submissionsOnly) {
    const queryString = this.buildQueryString(brand, submissionsOnly);
    return this.axiosObject.get(`${this.apiBaseName}/dealerships${queryString}`);
  }

  async getLastNames(brand) {
    const queryString = this.buildQueryString(brand);
    return this.axiosObject.get(`${this.apiBaseName}/last-names${queryString}`);
  }

  async validateDPTSId(dptsid, brand) {
    const queryString = this.buildQueryString(brand);
    return this.axiosObject.post(
      `${this.apiBaseName}/validate-dptsid${queryString}`,
      null,
      {
        params: {
          dptsid,
        },
      },
    );
  }

  async getZoneWinners(brand) {
    const queryString = this.buildQueryString(brand);
    return this.axiosObject.get(`${this.apiBaseName}/zone-winners${queryString}`);
  }

  async getNationalFinalists(brand) {
    const queryString = this.buildQueryString(brand);
    return this.axiosObject.get(`${this.apiBaseName}/finalists${queryString}`);
  }

  async getNationalWinners(brand) {
    const queryString = this.buildQueryString(brand);
    return this.axiosObject.get(`${this.apiBaseName}/final-winners${queryString}`);
  }
}
