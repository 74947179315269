<template>
  <metainfo />

  <template v-if="route.meta.layout">
    <component :is="route.meta.layout" />
  </template>
  <template v-else>
    <DefaultLayout />
  </template>
</template>

<script setup>
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';

const route = useRoute();

useMeta({
  description: 'EVolution Storytelling Competition',
});
</script>
