<template>
  <footer class="acura-footer">
    <div class="acura-footer__info">
      <img src="@/assets/img/EVolution_footer.png" alt="Evolution Logo" />

      <div class="acura-footer__text-container">
        <p class="acura-footer__support">Support</p>
        <p class="acura-footer__email">
          Email us at
          <a
            href="mailto:info@EVolutioncomp.com?subject=EVolution Competition Program Inquiry"
            aria-label="Select to email the PHQ team"
            ><b>info@EVolutioncomp.com</b></a
          >
        </p>
        <p class="acura-footer__call">
          Call us at
          <a href="tel:+18556538061" aria-label="Select to call the PHQ team on mobile devices">
            <b>(855) 653-8061</b>
          </a>
        </p>
      </div>
    </div>
    <div class="acura-footer__copy">
      <p class="">&copy; 2024 Jackson Dawson</p>

      <ul>
        <li>
          <a href="https://www.honda.com/privacy/privacy-notice" target="_blank" aria-label="Select to open privacy notice in a new tab.">
            Privacy Notice
          </a>
        </li>
        <li>
         <a href="https://www.honda.com/privacy/terms-and-conditions" target="_blank" aria-label="Select to open terms and conditions in a new tab">
          Terms and Conditions
        </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.acura-footer {
  background: #020610;
  color: #fff;
  padding: 170px 110px 70px;
  text-align: left;
  font-family: 'Myriad Pro';
  @media screen and (max-width: 600px) {
    padding: 44px 30px;
  }
  &__info {
    display: flex;
    gap: 16px;
    padding-bottom: 80px;
    border-bottom: 2px solid #224c71;
    img {
      padding-right: 24px;
      border-right: 1px solid #cdcdcd;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      text-align: center;
      img {
        border-right: none;
        border-bottom: 2px solid #cdcdcd;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }
  }

  &__support,
  &__email,
  &__call {
    font-size: clamp(1.25rem, 1.1783rem + 0.2681vw, 1.5rem);
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
  }

  &__support {
    font-weight: 700;
  }

  &__email {
  }

  &__call {
  }

  &__copy {
    padding-top: 64px;
    display: flex;
    justify-content: space-between;
    line-height: normal;
    ul {
      display: flex;
    }
    li {
      display: flex;
      &:not(:last-child) {
        border-right: 1px solid #cecece;
      }
      @media screen and (min-width: 600px) {
        &::before,
        &:not(:last-child)::after {
          content: '';
          height: 100%;
          display: block;
          width: 16px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      gap: 12px;
      ul {
        flex-direction: column;
        align-items: center;
        gap: 12px;
        li:not(:last-child) {
          border: none;
        }
      }
    }
  }
}
</style>
