import { baseCRUDAPI } from './base';

export default class Admin extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Auth');
  }

  async postLogin(key) {
    return this.axiosObject.post(
      `${this.apiBaseName}/validate-key`,
      null,
      {
        params: {
          key,
        },
      },
    );
  }

  // async postSearchCheckIn({
  //   authToken,
  //   locationId,
  //   email,
  //   lastName,
  //   phone,
  //   sessionId,
  //   eventType,
  //   attendeeTypes,
  // }) {
  //   const queryString = paramsSerializer({
  //     locationId,
  //     email,
  //     lastName,
  //     phone,
  //     sessionId,
  //     eventType,
  //     attendeeTypes,
  //   });
  //   return this.axiosObject.post(
  //     `${this.apiBaseName}/Search?${queryString}`,
  //     null,
  //     {
  //       headers: {
  //         authToken,
  //       },
  //     },
  //   );
  // }
}
