import {
  helpers,
  required,
  email,
} from '@vuelidate/validators';

const scrollToFirstError = (document) => {
  const invalidDivs = document.querySelectorAll('.is-invalid');
  console.log('entered ::: ', invalidDivs);
  if (invalidDivs.length > 0) {
    console.log('invalidDivs ::: ', invalidDivs);
    const elementPosition = invalidDivs[0]
      .getBoundingClientRect().top + (window.scrollY - 56) ?? 0;

    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth',
    });
  }
};

const emailValidator = helpers.withMessage('Please provide a valid email address.', email);
const regexYoutube = helpers.regex(
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu\.be))(\/(?:[\w-]+?v=|embed\/|v\/|shorts\/)?)([\w-]+)(\S+)?$/,
);
const regexPhone = helpers.regex(
  /^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/,
);
const regexDPTSID = helpers.regex(/^[A-Za-z]\d{6}$/);
const DPTSIDValidator = helpers.withMessage('Please provide a valid DPTS ID.', regexDPTSID);
const youtubeValidator = helpers.withMessage('Please provide a valid video link.', regexYoutube);
const phoneValidator = helpers.withMessage(
  'Please provide a valid phone number including area code.',
  regexPhone,
);
const requiredValidator = helpers.withMessage('Field is required', required);

export {
  scrollToFirstError,
  DPTSIDValidator,
  emailValidator,
  youtubeValidator,
  phoneValidator,
  requiredValidator,
};
