<template>
  <InputText ref="inputRef" />
</template>

<script setup>
import {
  defineProps,
  ref,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import InputText from 'primevue/inputtext';
import IMask from 'imask';

const inputRef = ref(null);

const props = defineProps({
  mask: String,
});

onMounted(() => {
  if (inputRef.value && props.mask) {
    const inputElement = inputRef.value.rootEl;
    const maskOptions = {
      mask: props.mask,
    };
    inputElement.maskRef = IMask(inputElement, maskOptions);
  }
});

onBeforeUnmount(() => {
  if (inputRef.value && props.mask) {
    const inputElement = inputRef.value.rootEl;
    if (inputElement && inputElement.maskRef) {
      inputElement.maskRef.destroy();
    }
  }
});
</script>
