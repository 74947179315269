<template>
  <p class="page__title">EVolution Competition | Submission Review</p>

  <template v-if="!showCheckIn">
    <login-page
      legend="Login to the Submission Review page"
      :loading="loadingLogin"
      @enableCheckIn="enableCheckIn($event)"
    />
  </template>

  <template v-else>
    <template v-if="showCheckIn">
      <SubmissionReviewFilters class="submissions__filters" @applyFilters="applyFilters" />
    </template>

    <template v-if="showLoading">
      <div class="submissions-loader">
        <TheLoading />
      </div>
    </template>
    <template v-else-if="showEmptyMessage">
      <p class="empty-message">
        No results matched your filters
      </p>
    </template>
    <template v-else>
      <TheFieldset legend="Review Submissions" class="submissions__table">
        <section>
          <p class="review-submissions__title">
            Reviewed Count: {{ totalReviewed || 0 }} | Rejected Count: {{ totalRejected || 0 }}
          </p>
        </section>

        <DataTable
          :value="submissions"
          :sortField="tableSort.field"
          :sortOrder="tableSort.order"
          @update:sortField="tableSort.field = $event"
          @update:sortOrder="tableSort.order = $event"
          scrollable
          scrollHeight="60vh"
          tableStyle="min-width: 50rem">
          <Column field="lastName" sortable header="Last Name" style="width: 15%"></Column>
          <Column field="zoneNumber" sortable header="Zone" style="width: 5%"></Column>
          <Column field="districtLetter" sortable header="District" style="width: 5%"></Column>
          <Column field="quantity" header="Video Link" style="width: 10%">
            <template #body="slotProps">
              <a
                class="video-link"
                :href="slotProps.data.youtubeLink"
                target="_blank">View Video</a>
            </template>
          </Column>
          <Column field="rejected" sortable header="Rejected" style="width: 10%">
            <template #body="slotProps">
              <Checkbox
              v-model="slotProps.data.rejected"
              disabled
              binary
              class="centered-checkbox" />
            </template>
          </Column>
          <Column field="reviewed" sortable header="Reviewed" style="width: 10%">
            <template #body="slotProps">
              <Checkbox
              v-model="slotProps.data.reviewed"
              disabled
              binary
              class="centered-checkbox" />
            </template></Column>
          <Column
            field="confirmationSent" sortable header="Confirmation Sent" style="width: 10%">
            <template #body="slotProps">
              <Checkbox
              v-model="slotProps.data.confirmationSent"
              disabled
              binary
              class="centered-checkbox" />
            </template></Column>
          <Column field="rejectionSent" sortable header="Rejection Sent" style="width: 10%">
            <template #body="slotProps">
              <Checkbox
              v-model="slotProps.data.rejectionSent"
              disabled
              binary
              class="centered-checkbox" />
            </template></Column>
          <Column header="Edit Details" style="width: 10%">
            <template #body="slotProps">
              <button class="edit-btn" @click="openModal(slotProps.data)">
                <img class="edit-btn__image" src="@/assets/img/edit.svg" alt="Edit icon" />
              </button>
            </template>
          </Column>
          <Column field="totalScore" sortable header="Total Score" style="width: 15%"></Column>
        </DataTable>

        <Button
        class="export-btn"
        @click="exportData"
        :disabled="isExporting"
        :aria-label="!isExporting ? 'EXPORT DATA' : 'Exporting Data'"
        :label="!isExporting ? 'EXPORT DATA' : 'Exporting Data...'"
      />
      </TheFieldset>
      <Dialog
        v-model:visible="showDetailsModal"
        modal
        :draggable="false"
        header="Edit Details"
        style="width: 90%">
        <SubmissionDetailsModal
          :apiKey="apiKey"
          :selectedEntry="selectedEntry"
          @handleClose="closeDetailsModal" />
      </Dialog>
    </template>
  </template>
</template>

<script setup>
import {
  ref,
  watch,
} from 'vue';
import LoginPage from '@/components/LoginPage.vue';
import TheFieldset from '@/components/TheFieldset.vue';
import SubmissionDetailsModal from '@/components/modals/SubmissionDetailsModal.vue';
import SubmissionReviewFilters from '@/components/submission-review/SubmissionReviewFilters.vue';
import { useToast } from 'vue-toastification';
import api from '@/api';

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import TheLoading from '@/components/TheLoading.vue';

const showCheckIn = ref(false);
const toast = useToast();

const submissions = ref([]);

const lastFilters = ref(null);
const totalReviewed = ref(null);
const totalRejected = ref(null);
const showLoading = ref(false);
const showEmptyMessage = ref(false);
const selectedEntry = ref(null);
const showDetailsModal = ref(false);
const apiKey = ref(null);
const tableSort = ref({ field: null, order: null });
const isExporting = ref(false);

const enableCheckIn = (event) => {
  showCheckIn.value = true;
  apiKey.value = event;
};

const fetchRegistrations = async (filters) => {
  showEmptyMessage.value = false;
  showLoading.value = true;
  lastFilters.value = filters;
  try {
    const { data } = await api.registrationForm.getRegistrations(filters, apiKey.value);
    const { reviewed, rejected, registrationForms } = data;
    submissions.value = registrationForms;
    totalReviewed.value = reviewed;
    totalRejected.value = rejected;
    if (submissions.value.length === 0) {
      showEmptyMessage.value = true;
    }
  } catch ({ response }) {
    toast.error(response.data.message || 'An error ocurred while fetching registrations. Please try again.');
  } finally {
    showLoading.value = false;
  }
};

const applyFilters = (filtersToApply) => {
  fetchRegistrations(filtersToApply);
};

const openModal = (selected) => {
  showDetailsModal.value = true;
  selectedEntry.value = {
    ...selected,
    evAppScore: selected.evAppScore?.toString(),
    storytellingScore: selected.storytellingScore?.toString(),
    accuracyScore: selected.accuracyScore?.toString(),
    enthusiasmScore: selected.enthusiasmScore?.toString(),
  };
};

const closeDetailsModal = () => {
  showDetailsModal.value = false;
  selectedEntry.value = null;

  fetchRegistrations(lastFilters.value);
};

const exportData = async () => {
  isExporting.value = true;
  try {
    const { data } = await api.registrationForm.getCSV(lastFilters.value, apiKey.value);
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'evolution_competition_submission_export.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch ({ response }) {
    toast.error(response.data.message || 'An error ocurred while exporting data. Please try again.');
  } finally {
    isExporting.value = false;
  }
};

watch(
  () => showCheckIn.value,
  () => {
    if (showCheckIn.value) {
      fetchRegistrations({ acura: true, honda: true });
    }
  },
);
</script>

<style scoped lang="scss">
.page__title {
  font-size: clamp(1.5rem, 1.1538rem + 1.5385vw, 3rem);
  line-height: normal;
  margin-top: 32px;
}

.submissions {
  &__filters, &__table {
    width: 95%;
    max-width: 1980px;
    margin: 32px auto 0;
    display: block;
  }
}

.video-link {
  color: #4291d1;
  text-decoration: underline;
}

.empty-message {
  font-size: clamp(0.75rem, 0.5481rem + 0.8974vw, 1.625rem);
  margin-top: 32px;
}

.submissions-loader {
  margin-top: 32px;
}

.review-submissions {
  &__title {
    text-align: center;
  }
}

.centered-checkbox {
  display: block;
  margin: 0 auto;
}

.edit-btn {
  border: none;
  background: transparent;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  &__image {
    width: 20px;
    fill: currentColor;
  }
}

.export-btn {
  margin-top: 32px;
}

@media (prefers-color-scheme: dark) {
.edit-btn {
  color: white;
}
}

</style>
