<template>
  <div class="home__wrapper" :style="dynamicStyles">
    <IntroTitle showWinners />
    <Card class="card__first" hasShadow>
      <p class="card__title">National Finalists</p>
      <template v-if="loadingNational">
        <TheLoading />
      </template>
      <div class="card__national__wrapper" v-else>
        <Card
          class="card__national-finalist"
          :key="'national-' + nationalFinalist.lastName"
          v-for="nationalFinalist in nationalFinalists"
        >
          <div class="card__national-finalist__name">
            <p>{{ nationalFinalist.firstName }}</p>
            <p>{{ nationalFinalist.lastName }}</p>
          </div>
          <p class="card__national-finalist__dealership-name">
            {{ nationalFinalist.dealershipName }}
          </p>
        </Card>
      </div>
    </Card>
    <Card class="card" hasShadow>
      <p class="card__title">Zone Winners</p>
      <template v-if="loadingZone">
        <TheLoading />
      </template>
      <div class="card__zone__wrapper" v-else>
        <template :key="'zone-' + index" v-for="(data, index) in zoneWinners">
        <p class="card__zone__name">Zone {{ data.zone }}</p>
        <Card class="card__zone-winner">
          <div
            class="card__zone-winner__position"
            :key="'zone-winner-' + winner.firstName"
            v-for="winner in data.zoneWinners"
          >
          <div class="card__zone-winner__position__wrapper">
            <p class="card__zone-winner__position__name">
              {{ winner.firstName }} {{ winner.lastName }}
            </p>
            <hr class="card__zone-winner__divider"/>
          </div>
          <div class="card__zone-winner__position__wrapper">
            <p class="card__zone-winner__position__classification">
              {{ parsePositionOnFinals(winner.place) }} place
            </p>
            <hr class="card__zone-winner__divider"/>
          </div>
            <p class="card__zone-winner__position__dealer-name">{{ winner.dealershipName }}</p>
          </div>
        </Card>
      </template>
      </div>
    </Card>
  </div>
  <div class="footer__wrapper">
    <p class="footer__text">
      * Must travel to and compete in National Competition to receive National Prizes. Participants
      for the National Competition were chosen from ALL valid video submissions regardless of Zone.
    </p>
    <img class="footer__image" src="@/assets/img/winners-bg.png" alt="Las vegas view" />
    <span class="footer__overlay"></span>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDynamicStyles from '@/utils/BrandFonts';
import useVariant from '@/utils/useVariant';
import IntroTitle from '@/components/intro/IntroTitle.vue';
import Card from '@/components/TheCard.vue';

import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import api from '@/api';
import TheLoading from '@/components/TheLoading.vue';

const route = useRoute();
const variant = useVariant(route.params);
const dynamicStyles = useDynamicStyles(variant);
const toast = useToast();

const loadingNational = ref(false);
const loadingZone = ref(false);
const nationalFinalists = ref([]);

const zoneWinners = ref([]);

const fetchZoneWinners = async () => {
  loadingZone.value = true;
  try {
    const { data } = await api.data.getZoneWinners(variant);
    zoneWinners.value = data;
  } catch {
    toast.error('An error ocurred while fetching zone finalists. Please try again.');
  } finally {
    loadingZone.value = false;
  }
};

const fetchNationalFinalists = async () => {
  loadingNational.value = true;
  try {
    const { data } = await api.data.getNationalFinalists(variant);
    nationalFinalists.value = data;
  } catch {
    toast.error('An error ocurred while fetching national finalists. Please try again.');
  } finally {
    loadingNational.value = false;
  }
};

const parsePositionOnFinals = (place) => {
  const positions = {
    1: 'FIRST',
    2: 'SECOND',
    3: 'THIRD',
  };
  return positions[place];
};

onMounted(() => {
  fetchZoneWinners();
  fetchNationalFinalists();
});
</script>

<style scoped lang="scss">
@mixin glow {
  border: 3px solid #5a91d1;
  box-shadow: 0px 0px 6px #5a91d1;
}

.home__wrapper {
  display: grid;
  gap: 24px;
}

.card {
  max-width: 84vw;
  padding: 56px 50px;
  &__first {
    margin-top: 0;
    @media screen and (min-width: 768px) {
      margin-top: -200px;
    }
  }
  &__title {
    font-family: var(--font-primary);
    font-size: clamp(2rem, 1.5962rem + 1.7949vw, 3.75rem);
    line-height: normal;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &__national__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 38px;
    row-gap: 53px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }
  &__national-finalist {
    padding: 30px 20px !important;
    line-height: normal;
    &__name {
      font-size: clamp(1.125rem, 0.9231rem + 0.8974vw, 2rem);
      border-bottom: 1px solid #4291d1;
      padding-bottom: 24px;
      @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
        gap: 4px;
      }
    }

    &__dealership-name {
      margin-top: 24px;
      font-size: clamp(1.125rem, 1.0385rem + 0.3846vw, 1.5rem);
      color: #767676;
      text-transform: uppercase;
    }
  }
  &:not(__first) {
    @media screen and (max-width: 768px) {
      padding: 16px 16px;
    }
  }
  &__national-finalist,
  &__zone-winner {
    @include glow;
  }

  &__zone {
    &-winner {
      padding: 32px 16px;
      &:not(:last-child) {
        margin-bottom: 80px;
      }
      &__position {
        display: grid;
        grid-template-columns: 40% 20% 40%;
        text-align: left;
        font-size: clamp(1.125rem, 1.0385rem + 0.3846vw, 1.5rem);
        line-height: normal;
        text-transform: uppercase;
        padding: 16px 0;

        p {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            padding: 0px 16px;
            margin: 0 auto;
              display: flex;
              flex-direction: column;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #707070;
        }

        @media screen and (max-width: 767px) {
          grid-template-columns: 1fr;
          text-align: center;

        }

        @media screen and (min-width: 768px) {
          &__wrapper {
              display: flex;
          }
          &__divider {
            width: 2px;
            height: 100%;
          }
          &__name, &__classification {
            flex: 1;
          }

          &__dealer-name {
            margin-left: 24px;
          }
        }
        &__name {
          font-size: clamp(1.5rem, 1.3846rem + 0.5128vw, 2rem);
        }
        &__classification {
          text-align: center;
          justify-content: center;
        }
      }

      &__divider {
        border-color: #5A91D1;
      }

      @media screen and (max-width: 767px) {
        padding: 50px 32px;
        &__divider {
          width: 40px;
          height: 2px;
        }
      }
    }
    &__name {
      font-family: var(--font-primary);
      font-weight: 700;
      color: #707070;
      font-size: clamp(1.5rem, 1.3269rem + 0.7692vw, 2.25rem);
      margin-bottom: 32px;
    }
  }
}

.footer {
  &__wrapper {
    margin-top: 24px;
    position: relative;
    background: black;
  }
  &__image {
    width: 100%;
    max-width: auto;
  }
  &__overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: linear-gradient(
      to bottom,
      #fafafa 0%,
      #fafafa 25%,
      rgba(255, 255, 255, 0) 40%
    );

    @media screen and (max-width: 419px) {
    background-image: linear-gradient(
      to bottom,
      #fafafa 0%,
      #fafafa 50%,
      rgba(255, 255, 255, 0) 70%
      );
    }

    @media screen and (min-width: 420px) and (max-width: 767px) {
      background-image: linear-gradient(
        to bottom,
        #fafafa 0%,
        #fafafa 45%,
        rgba(255, 255, 255, 0) 70%
      );
    }
    @media screen and (min-width: 768px) {
      background-image: linear-gradient(
        to bottom,
        #fafafa 0%,
        rgba(255, 255, 255, 0) 30%
      );
    }
  }
  &__text {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 20px 15% 0;
    background: transparent;
    font-size: clamp(0.9375rem, 0.8077rem + 0.5769vw, 1.5rem);
    line-height: normal;
    font-style: italic;
    font-weight: 500;
    font-family: var(--font-primary);
    @media screen and (max-width: 767px) {
      position: relative;
      padding: 10px 8% 0;
    }
  }
}
</style>
