<template>
  <TheHeader :winnersPage="route.meta.winners" />
  <main style="flex: 1">
    <router-view />
  </main>
  <TheFooter />
</template>

<script setup>
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>
