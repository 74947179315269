<template>
  <TheFieldset legend="Filter Submissions">
    <form class="filter__form" @submit.prevent="submitForm">
      <div class="filter__form__wrapper">
        <TheDatepicker label="From" v-model="state.from" />

        <NewSelect
              v-model="state.districts"
              fluid
              multiple
              filter
              :maxSelectedLabels="3"
              :options="districtOptions"
              label="Select Districts"
              id="districtsSelect"
            />
            <NewSelect
            v-model="state.dealerships"
            fluid
            multiple
            filter
            :maxSelectedLabels="3"
            :options="dealershipsOptions"
            label="Select Dealerships"
            id="districtsSelect"
          />

          <TheCheckbox
            v-model="state.acura"
            id="submission-acura"
            text="Acura "
          />

          <TheDatepicker label="To" v-model="state.to" />

          <NewSelect
            v-model="state.zones"
            fluid
            multiple
            filter
            :maxSelectedLabels="3"
            :options="zoneOptions"
            label="Select Zones"
            id="districtsSelect"
          />
          <NewSelect
            v-model="state.lastNames"
            fluid
            multiple
            filter
            :maxSelectedLabels="3"
            :options="lastNamesOptions"
            label="Select Last Names"
            id="districtsSelect"
          />
          <TheCheckbox
            v-model="state.honda"
            id="submission-honda"
            text="Honda "
          />
      </div>
      <div class="buttons__wrapper">
          <GlowButton type="submit" class="border-2" @click="applyFilters">
            Search
          </GlowButton>
          <GlowButton type="submit" class="border-2" @click="clearFilters">
            Clear Filters
          </GlowButton>
      </div>
    </form>
  </TheFieldset>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

import api from '@/api';
import TheCheckbox from '../TheCheckbox.vue';
import TheFieldset from '../TheFieldset.vue';
import TheDatepicker from '../TheDatepicker.vue';
import NewSelect from '../NewSelect.vue';
import GlowButton from '../GlowButton.vue';

const toast = useToast();

defineProps({
  showFilters: Boolean,
});

const emit = defineEmits(['applyFilters']);

const state = ref({
  from: '',
  to: '',
  districts: [],
  dealerships: [],
  zones: [],
  lastNames: [],
  acura: true,
  honda: true,
});

const districtOptions = ref([]);
const dealershipsOptions = ref([]);
const zoneOptions = ref([]);
const lastNamesOptions = ref([]);

const fetchDistricts = async () => {
  try {
    const { data } = await api.data.getDistrictLetters(null, true);
    districtOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Districts. Please try again.');
  }
};
const fetchZoneCodes = async () => {
  try {
    const { data } = await api.data.getZoneCodes(null, true);
    zoneOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Zone Codes. Please try again.');
  }
};

const fetchDealerships = async () => {
  try {
    const { data } = await api.data.getDealerships(null, true);
    dealershipsOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Zone Codes. Please try again.');
  }
};

const fetchLastNames = async () => {
  try {
    const { data } = await api.data.getLastNames();
    lastNamesOptions.value = data;
  } catch {
    toast.error('An error ocurred while fetching Last Names. Please try again.');
  }
};

const submitForm = () => {
  /* eslint-disable-next-line */
  const filtersToApply = Object.fromEntries(Object.entries(state.value).filter(([key, value]) => !!value && value.length > 0));

  if (state.value.from) {
    filtersToApply.from = state.value.from.toISOString().replace('.000', '');
  }

  if (state.value.to) {
    filtersToApply.to = state.value.to.toISOString().replace('.000', '');
  }

  filtersToApply.acura = state.value.acura;
  filtersToApply.honda = state.value.honda;

  emit('applyFilters', filtersToApply);
};

const clearFilters = () => {
  state.value = {
    from: '',
    to: '',
    districts: [],
    dealerships: [],
    zones: [],
    lastNames: [],
    acura: true,
    honda: true,
  };
  emit('applyFilters', state.value);
};

onMounted(() => {
  fetchDistricts();
  fetchZoneCodes();
  fetchDealerships();
  fetchLastNames();
});
</script>

<style scoped lang="scss">
.filter {
  &__form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &__wrapper {
      grid-column: span 3 / span 3;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 16px;
      row-gap: 8px;
      align-items: center;
    }
  }
}
.buttons__wrapper {
  grid-row: span 2 / span 2;
  display: flex;
  align-items: center;
  button {
    font-size: 16px;
    height: 48px;
  }
}
</style>
