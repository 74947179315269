<template>
  <InputGroup>
    <Button :label="label" />
    <DatePicker
      label="Date input"
      id="for-date"
      v-model="date"
      showIcon
      fluid
      iconDisplay="input" />
  </InputGroup>
</template>

<script setup>
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import DatePicker from 'primevue/datepicker';
import { computed } from 'vue';

const emit = defineEmits(['update:value']);
const props = defineProps({
  label: String,
  modelValue: Date,
});

const date = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
.p-inputgroup {
  height: 52px;
  > button {
    min-width: 64px;
    background: #4291d1;
    border: none;

    &:hover {
      background: #2783ce;
      transition: .5s all;
      border: none;
    }
  }
}
.p-datepicker {
  button {
    background: #FFF !important;
  }
}

</style>
