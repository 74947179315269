<template>
<div v-if="isVisible">
  <div class="modal__overlay">
    <div class="modal__wrapper">
      <div class="modal__body">
        <button class="modal__close-btn" @click="close()" aria-label="Select to close modal">
          <img src="@/assets/img/close-modal.svg" alt="Close Icon" />
        </button>
        <div class="modal__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {
  ref,
  watch,
  defineProps,
  defineEmits,
} from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const isVisible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isVisible.value = newValue;
    if (isVisible.value) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
);

const close = () => {
  emit('update:modelValue', false);
  // console.log('Fechou!');
};
</script>

<style lang="scss" scoped>
.modal {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    z-index: 1000;
  }

  &__wrapper {
    position: relative;
    width: 95vw;
    max-width: 1900px;
    max-height: 95%;
    background: #EEEEEE;
    border-radius: 8px;
    padding: 20px;
    margin-top: 2.5%;
    border: 4px solid #4291d1;
  }

  &__body {
    position: relative;
  }

  &__close-btn {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 69.5px;
    background: black;
    border: 2px solid #4291d1;
    border-radius: 50%;
    aspect-ratio: 1;
    color: white;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: 40px;
    }
  }

  &__content {
    max-width: 95vw;
    // max-height: 1900px; /* Constrain the content height */
    // overflow-y: visible; /* Enable scrolling inside if content exceeds 1900px */
    padding: 40px 0 20px 3.5vw;
    @media screen and (max-width: 768px) {
      padding: 40px 0 20px 20px;
    }
  }
}
</style>
