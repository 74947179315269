const EvApp = ['0', '10'];
const Storytelling = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
const Accuracy = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const Enthusiasm = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export {
  EvApp,
  Storytelling,
  Accuracy,
  Enthusiasm,
};
