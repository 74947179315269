import paramsSerializer from '@/utils/paramsSerializer';
import { baseCRUDAPI } from './base';

export default class RegistrationForm extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Registration-Form');
  }

  async getRegistrations(filters, apiKey) {
    const queryString = paramsSerializer(filters);
    return this.axiosObject.get(
      `${this.apiBaseName}${queryString}`,
      {
        headers: {
          'X-API-Key': apiKey,
        },
      },
    );
  }

  async getCSV(filters, apiKey) {
    const queryString = paramsSerializer(filters);
    return this.axiosObject.get(
      `${this.apiBaseName}/csv${queryString}`,
      {
        headers: {
          'X-API-Key': apiKey,
        },
      },
    );
  }

  async postSubmit(data, brand = 'honda') {
    return this.axiosObject.post(
      `${this.apiBaseName}/${brand}`,
      data,
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    );
  }

  async putRegistration(id, data, apiKey) {
    return this.axiosObject.put(
      `${this.apiBaseName}/${id}`,
      data,
      {
        headers: {
          'X-API-Key': apiKey,
        },
      },
    );
  }
}
