<template>
  <div
    class="select-field"
    :class="{ 'select-field--invalid is-invalid': dirty && !!errorMessage }"
    :data-invalid="dirty && !!errorMessage"
  >
    <label class="select-field__label" :for="`select-${Math.random()}`">
      {{ inputPlaceholder }}
      <div class="select-field__wrapper">
        <select
          v-model="inputValue"
          :class="['select-field__select', customClass]"
          :disabled="disabled"
          :aria-label="label"
          :data-small="isSmall"
        >
          <option
            class="select-field__option select-field__option--placeholder"
            disabled
            value=""
          >
            {{ inlineOption || inputPlaceholder }}
          </option>
          <option
            v-for="option in options"
            v-text="option.text || option"
            class="select-field__option"
            :key="option.value || option"
            :value="option.value || option"
          />
        </select>
      </div>
      <small v-if="!hideErrorMessage" class="select-field__error-message">
        {{ errorMessage }}
      </small>
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: { type: [String, Number], default: '' },
  label: { type: String, default: '' },
  hideErrorMessage: { type: Boolean, required: false },
  inlineOption: { type: String, required: false },
  options: { type: Array, default: () => [] },
  fixedLabel: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  dirty: { type: Boolean, default: false },
  customClass: { type: String, default: '' },
  errorMessage: { type: String, default: '' },
  isSmall: { type: Boolean, default: false },
});

const inputPlaceholder = computed(() => (props.required ? `${props.label}*` : props.label));

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
.select-field {
  text-align: left;
  &--invalid {
    & .select-field__select {
      border-color: red;
    }
    & .select-field__error-message {
      display: block;
    }
  }

  &__label {
    font-size: 16px;
    margin-bottom: 0.25rem;
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__select {
    width: 100%;
    height: 3.5rem;
    padding-left: 1.25rem;
    padding-right: 2rem;
    background-color: transparent;
    border: 2px solid #CDCDCD;
    color: #393939;
    font-size: 14px;
    border-radius: 0.5rem;
    outline-offset: 4px;
    cursor: pointer;
    appearance: none;
    background-image: url('@/assets/img/chevron-down.svg');
    background-size: 32px;
    background-repeat: no-repeat;
    background-position-x: 99.7%;
    background-position-y: 50%;
    &[disabled] {
      cursor: default;
    }
    &[data-black='true'] {
      color: #393939;
    }
    &[data-small='true'] {
      height: 2.5rem;
    }
  }

  &__option {
    &:disabled {
      color: #9c9c9c;
    }
    &--placeholder {
      color: #9c9c9c;
    }
  }

  &__error-message {
    display: none;
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
}
</style>
