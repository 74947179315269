<template>
  <div
    class="input-field"
    :class="{ 'is-invalid': dirty && !!errorMessage }">
    <label :for="`textfield--${name}`" class="input-field__label">
      <span v-if="label" class="input-field__label-text">
        {{ label }}
      </span>
      <span v-if="tooltip" class="input-field__tooltip">
        <p>{{ tooltip }}</p>
      </span>
      <div class="input-field__wrapper">
        <input
          class="input-field__input"
          :class="errorMessage ? 'input-field__input--error' : 'input-field__input--default'"
          :type="type"
          ref="input"
          :id="`${id ? id : 'textfield--' + name}`"
          :name="`textfield--${name}`"
          :placeholder="placeholder"
          :tooltip="tooltip"
          :value="value"
          :maxLength="maxLength"
          :max="max"
          :min="min"
          :dirty="dirty"
          :disabled="disabled"
          :autocomplete="autocomplete"
          @input="$emit('update:value', $event.target.value)"
        />
      </div>
      <small v-if="errorMessage" class="input-field__error-message">
        {{ errorMessage }}
      </small>
    </label>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import IMask from 'imask';

const input = ref(null);

const props = defineProps({
  name: String,
  label: String,
  tooltip: String,
  type: String,
  id: String,
  placeholder: String,
  mask: { String, Number },
  errorMessage: String,
  value: String,
  maxLength: Number || String,
  max: Number,
  min: Number,
  dirty: Boolean,
  disabled: Boolean,
  autocomplete: String,
});

onMounted(() => {
  if (props.mask) {
    IMask(input.value, {
      mask: props.mask,
      pattern: 'C',
      blocks: {
        C: {
          mask: /^.+$/,
        },
      },
    });
  }
  if (props.initialFocus) {
    input.value.focus();
  }
});

defineEmits(['update:value']);
</script>

<style scoped lang="scss">
.input-field {
  position: relative;
  text-align: left;

  &__label {
    display: block;
    font-family: var(--font-primary);
    font-size: 0.875rem;
    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__label-text {
    display: block;
    margin-bottom: 0.5rem;
  }

  &__tooltip {
    position: absolute;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.25rem;
    color: white;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    margin-top: -2rem;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 80%;
      width: 0;
      height: 0;
      border-top: 0.5rem solid rgba(0, 0, 0, 0.8);
      border-right: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
    }
  }

  &:hover .input-field__tooltip,
  &:focus-within .input-field__tooltip {
    visibility: visible;
    opacity: 1;
  }

  &__input {
    display: block;
    padding: 0.25rem 0.75rem;
    width: 100%;
    outline: none;
    border-radius: 0.375rem;
    background-color: #FAFAFA;
    height: 3.25rem;
    border: 2px solid #CDCDCD;
    font-size: 14px;

    &--error {
      border-color: #E82C2A;
    }

    &--default:focus {
      border-color: #4391D1;
    }
  }

  &__error-message {
    color: #E82C2A;
  }
}
</style>
