<template>
  <main>
    <router-view />
  </main>
</template>

<style scoped>
main {
  flex: 1;
  background: #FFF;
}

@media (prefers-color-scheme: dark) {
  main {
    background: #18181B;
    color: #FFF;
  }
}
</style>
