import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import WinnersView from '@/views/WinnersView.vue';
import SubmissionReviewView from '@/views/SubmissionReviewView.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import NationalWinnersView from './views/NationalWinnersView.vue';

const shouldRenderWinner = () => {
  const currentDate = new Date();
  const nationalWinnersDate = new Date(Date.UTC(2024, 9, 22, 0 + 4, 0, 0));
  const winnersDate = new Date(Date.UTC(2024, 8, 16, 15 + 4, 0, 0));

  if (currentDate >= nationalWinnersDate) {
    return NationalWinnersView;
  }
  if (currentDate >= winnersDate) {
    return WinnersView;
  }
  return HomeView;
};

const routes = [
  {
    path: '/:brand?',
    name: 'home',
    component: shouldRenderWinner(),
    meta: {
      layout: DefaultLayout,
      winners: true,
    },
  },
  {
    path: '/:brand?/contest',
    name: 'contest',
    component: HomeView,
    meta: {
      layout: DefaultLayout,
      winners: false,
    },
  },
  {
    path: '/:brand?/national-winners',
    name: 'national-winners',
    component: NationalWinnersView,
    meta: {
      layout: DefaultLayout,
      winners: true,
    },
  },
  {
    path: '/:brand?/winners',
    name: 'winners',
    component: WinnersView,
    meta: {
      layout: DefaultLayout,
      winners: true,
    },
  },
  {
    path: '/submission-review',
    name: 'submission-review',
    component: SubmissionReviewView,
    meta: {
      layout: BlankLayout,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
